<template>
  <v-dialog v-model="dialog" max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
       <span>Timeline "{{ getClientName(project.budget.client_id) }} &raquo; {{ project.budget.title }}" </span>
      </v-card-title>
      <v-card-text style="font-size: 1rem">
        <v-timeline align-top dense>
          <v-timeline-item
            small
            fill-dot
            color="#ccc"
            v-for="(item, index) in history"
            :key="item.id"
          >
            <v-row class="timeline-item">
              <v-col cols="2" class="mt-n3">
                <small
                  >{{ d2s(item.created_at, "short") }} <br />
                  {{ item.created_by }}</small
                >
              </v-col>

              <v-col cols="8" class="mt-n2">
                <span v-if="item.transfer"><li class="small">Kampagne {{ item.transfer.direction === "from" ? "aus der" : "in die" }}  Unit <u>{{ item.transfer.name }}</u> transferiert</li></span>
                <span v-else-if="index === 0 && item.timeline === 'CHANGE'"><li class="small">Kampagne hinzugefügt</li></span>
                <span v-else-if="item.timeline === 'COMMIT'" class="small"><li class="small">Zahlen ans Controlling gemeldet</li></span>
                <span v-else class="small" v-html="item.changes"></span>
              </v-col>
              <v-col cols="2" align="right">
                <small v-if="item.timeline === 'CHANGE'">FCID: {{ item.id || 'xxx' }}</small>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item small fill-dot v-if="with_live">
            <v-row class="timeline-item">
              <v-col cols="2" class="mt-n3">
                <small>
                  LIVE <br />
                  nicht gespeichert</small
                >
              </v-col>

              <v-col>
                <span v-html="now" class="small"></span>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" text color="primary">{{
          trl("close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { trl, getClientName, getAttributeName } from "@/utils/strings";
import { toLocale } from "@/utils/numbers";
import { d2s } from "@/utils/dates";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      dialog: false,
      history: [],
      now: null,
    };
  },
  props: ["project", "with_live"],
  methods: {
    async getHistory() {

      let commits = []
      
      this.forecasts.find(f => f.id === this.project.forecast_id).snapshots.forEach(s => {
          if (s.commits.length === 0) return;
          commits = commits.concat(s.commits.filter(c => c.division_id === this.project.division_id))
      });

      commits.forEach(c => c.timeline = "COMMIT")


      const data = await this.$store.dispatch("req", {
        route: "history/" + this.project.id,
      });


      data.forEach((p, index) => {
        
        this.$store.state.budget.fields.forEach(
          (f) => (p[f.key] = toLocale(p[f.key], f.round))
        );

        if (p.costs) {
          p.costs.forEach((c) => (c.value = toLocale(c.value, 2)));
        }

        p.timeline = "CHANGE";
        p.changes = this.getChanges(data,index);
       
        //p.changes = p.transfer ? [] : this.getChanges(data, index)

      });

      // 22-02-23: Wir zeigen die Commits doch nicht als Timeline-Item an und verwenden nur die echten Daten
      // let history = data.concat(commits).sort((a,b) => {
      //     return DateTime.fromSQL(a.created_at) - DateTime.fromSQL(b.created_at);
      // });

      let history = data;
      
      this.history = history
     
      this.now = this.getChanges(data);
      
    },
    getChanges(arr, index) {
      let cur, prev;

      if (!index) {
        cur = this.project.budget;
        prev = arr[arr.length - 1];
      } else {
        cur = arr[index];
        prev = arr[index - 1];
      }
      
      if (prev.transfer && !prev.id) return;

      let changes = [];

      let irrelevantFields = [
        "id",
        "created_at",
        "created_by",
        "costs",
        "initials",
      ].concat(
        this.$store.state.budget.fields
          .filter((f) => f.calculated)
          .map((f) => f.key)
      );

      for (let key in cur) {
        if (irrelevantFields.indexOf(key) > -1) continue;

        let f = this.$store.state.budget.fields.find((f) => f.key === key);

        if (f) {
          if (cur[key] !== prev[key]) {
            changes.push(
              "<strong>" +
                trl("budget_" + key) +
                "</strong>" +
                " von <u>" +
                prev[key] +
                " " +
                f.suffix +
                "</u> auf <u>" +
                cur[key] +
                " " +
                f.suffix +
                "</u>"
            );
          }
        } else if (cur[key] !== prev[key]) {
          let was = prev[key];
          let is = cur[key];
        
          switch (key) {
            case "client_id":
              was = this.getClientName(prev[key]);
              is = this.getClientName(cur[key]);
              break;
            case "attribute_id":
              was = this.getAttributeName(was);
              is = this.getAttributeName(is);
              break;
            case "status":
              let status_was = this.$store.state.budget.status.find(
                (item) => item.value == was
              );
              let status_is = this.$store.state.budget.status.find(
                (item) => item.value == is
              );
              was = `<i class="mdi ${status_was.icon}" color="${status_was.color}"></i><span>${status_was.label}</span>`;
              is = `<i class="mdi ${status_is.icon}" color="${status_is.color}"></i><span>${status_is.label}</span>`;
              break;
            case "comment":
              was = `"${was}"`;
              is = `"${is}"`;
              break;
          }

          if (key === "comment" && (was === '""' || is === '""')) {
            if (was === '""') {
              changes.push(
                "<strong>" +
                  trl("budget_" + key) +
                  "</strong> hinzugefügt: <u>" +
                  is +
                  "</u>"
              );
            } else if (is === '""') {
              changes.push(
                "<strong>" +
                  trl("budget_" + key) +
                  "</strong> entfernt: <u>" +
                  was +
                  "</u>"
              );
            }
          } else if (key === "deleted_at") {
            changes.push("Kampagne gelöscht");
          } else {
            changes.push(
              "<strong>" +
                trl("budget_" + key) +
                "</strong>" +
                " von <u>" +
                was +
                "</u> auf <u>" +
                is +
                "</u>"
            );
          }
        }
      }

      if (changes.length === 0) {
        changes = ["keine Änderungen"];
      }

      return "<li>" + changes.join("</li><li>") + "</li>";
    },
    trl,
    d2s,
    getClientName
  },
  computed: {
    ...mapGetters(["clients", "attributes", "forecasts"]),
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.project.rowIcon = "mdi-dots-horizontal";
        return;
      }
      this.project.rowIcon = "mdi-dock-window";
      this.getHistory();
    },
  },
};
</script>

<style>
.timeline-item u {
  color: dodgerblue;
  text-decoration: none;
  font-weight: bold;
}
</style>