<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        <i class="mdi mdi-transfer-right"></i
        ><span>Transferieren von Kampagnen und Kunden</span>
      </v-card-title>
      <p class="mx-6">Verschiebe Budget in eine andere Unit.<br />Sobald du die Änderungen übernimmst, wird die betroffene Kampagne in deiner Übersicht farblich hervorgehoben.
        Erst wenn du deine Budgetübersicht speicherst, werden die Daten in die gewählte Unit verschoben.
      </p>
     
      <div class="mx-6">
        <div>
          <v-radio-group
            mandatory
            label="Was soll transferiert werden?"
            v-model="transferContent"
          >
            <v-radio
              value="campaign"
              :label="`nur diese Kampagne (${project.budget.title})`"
            ></v-radio>
            <v-radio
              value="client_campaigns"
              :label="`alle Kampagnen dieses Kunden (${getClientName(project.budget.client_id)})`"
            ></v-radio>
          </v-radio-group>
        </div>
        <div></div>
        <div>
          <!-- <v-radio-group mandatory label="Wohin soll transferiert werden?" v-model="transferTarget">
            <v-radio @click="transferValue=null" value="division_id" :disabled="isOnlyDivision" label="in eine andere Unit"></v-radio>
            <v-radio @click="transferValue=null" value="forecast_id" :disabled="isOnlyForecast" label="in einen anderen Forecast"></v-radio>
          </v-radio-group> -->

          <v-select
            v-if="transferTarget == 'division_id'"
            outlined
            label="Unit wählen"
            v-model="transferValue"
            :items="validDivisions"
            item-disabled="disabled"
            item-value="id"
            item-text="name"
             :required="!transferValue"
          ></v-select>
          <v-select
            v-if="transferTarget == 'forecast_id'"
            outlined
            label="Forecast wählen"
            v-model="transferValue"
            :items="forecasts"
            item-value="id"
            item-text="title"
          ></v-select>

          <!-- <v-checkbox v-model="transferBudget" label="Budgetzahlen transferieren"></v-checkbox> -->
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text>{{ trl("cancel") }}</v-btn>
        <v-btn @click="accept" color="primary">{{ trl("accept") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { trl, getClientName } from "@/utils/strings";
import { isLocked } from "@/utils/dates";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      transferContent: "campaign",
      transferTarget: "division_id",
      transferValue: null,
      transferBudget: true,
    };
  },
  props: ["project"],
  computed: {
    ...mapGetters(["current", "forecasts", "divisions", "clients"]),
    validDivisions(){
      let data = [];
      this.divisions.forEach(d => {
        if (d.deleted_at) return;
        if (isLocked(d, this.forecasts.find((f) => f.id === this.$store.state.activeForecastId)?.fy)) return;
        data.push({
          id: d.id,
          name: d.name,
          disabled: d.id === this.project.division_id
        })
      })

      return data;

    },
    isOnlyDivision() {
      return this.divisions.length === 1;
    },
    isOnlyForecast() {
      return this.forecasts.filter((f) => f.active === 1).length === 1;
    },
  },
  methods: {
    accept() {

      if (!this.transferValue) return;


      let data = {
        transferProject: this.project.id,
        transferContent: this.transferContent,
        transferTarget: this.transferTarget,
        transferValue: this.transferValue,
        transferBudget: this.transferBudget,
      };

      this.$emit("accept", data); // <-- geht an @/components/BudgetContextMenu.vue
      this.dialog = false;
    },
    trl,
    getClientName
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.project.rowIcon = "mdi-dots-horizontal";
        return;
      }
      this.project.rowIcon = "mdi-dock-window";
    },
  },
};
</script>

<style>
</style>