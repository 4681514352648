<template>
  <v-container fluid class="pl-15 pr-12">
    <confirm ref="confirm" />
    <v-row>
      <v-col cols="9">
        <h1>
          {{ forecast.title }} {{ forecast.title ? "&ndash;" : "" }}
          {{ headline }}
        </h1>
      </v-col>
      <v-col cols="2" align="right" class="d-flex mt-2">
        <v-select
          class="ml-4"
          label="Snapshot wählen"
          v-model="selected.snapshot"
          v-if="forecast.snapshots"
          :items="
            forecast.snapshots
              .filter(
                (s) =>
                  s.closed_at ||
                  s.commits.map((c) => c.division_id).includes(division.id)
              )
              .concat({ id: null, title: 'live', commits: [] })
          "
          item-value="id"
          item-text="title"
          @change="navSnapshot"
          dense
          outlined
          hide-details
          prepend-inner-icon="mdi-calendar-star"
        >
         <template slot="item" slot-scope="{ item }">
              <div class="d-flex justify-space-between" style="width:100%">
                <div><strong>{{ item.title }}</strong></div>
                <div style="color:#777">{{ item.commits.find(c => c.division_id === division.id) ? d2s(item.commits.find(c => c.division_id === division.id).created_at, 'dateshort') : "---" }}</div>
              </div>
            </template>
        
        </v-select>
      </v-col>
      <v-col cols="1" align="right" class="mt-2">
        <DivisionUsersModal :division="division" />
        <Download
          v-if="
            permissions.FC_DIVISION_EXPORT &&
            projects.filter((p) => !p.deleted_at).length > 0
          "
          :query="{
            fcid: forecast.id,
            division: division.id,
            snapshot: selected.snapshot,
          }"
        />
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      indeterminate
      rounded
      color="primary"
    ></v-progress-linear>

    <div v-else>
      <v-banner
        color="accent"
        single-line
        rounded
        v-if="!this.forecast.id"
      >
        <v-layout align-baseline>
          <v-icon>mdi-alert-octagon</v-icon>
          <div class="ml-2">
            Das Controlling hat keinen Forecast angelegt. Eine Eingabe
            ist nicht möglich.
          </div>
          <v-spacer></v-spacer>
        </v-layout>
      </v-banner>



      <div v-else>
        <table
          class="budget mt-4"
          v-if="projects.length > 0"
          v-click-outside="onClickOutside"
        >
          <thead>
            <tr>
              <th class="left">
                <v-icon class="mr-2" ripple="false" dense @click="toggleAll">
                  {{
                    groups.filter((g) => g.collapsed).length === groups.length
                      ? "mdi-chevron-right"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
                <span
                  >{{ trl("budget_client_id") }} /
                  {{ trl("budget_project") }}</span
                >
              </th>
              <th>{{ trl("budget_attribute_id") }}</th>
              <th class="sep">{{ trl("budget_status") }}</th>

              <th
                v-for="f in $store.state.budget.fields"
                :key="f.key"
                :class="{ sep: f.endOfGroup }"
              >
                {{ trl(`budget_${f.key}`) }}
              </th>

              <th style="min-width: 160px">{{ trl("budget_comment") }}</th>
              <th></th>
            </tr>

            <tr class="totals">
              <th colspan="2"></th>
              <th class="sep">
                <PieChart :data="projects" :localValues="true" :label="this.division.name" />
              </th>
              <th
                v-for="f in $store.state.budget.fields"
                :key="f.key"
                class="sum right"
                :class="{
                  zero: fromLocale(calculateColumn(f.key)) === 0,
                  sep: f.endOfGroup,
                }"
              >
                <span v-if="f.sumup"
                  >{{ calculateColumn(f.key)
                  }}<span class="suffix disabled" v-html="f.suffix"></span
                ></span>
              </th>
              <th>&nbsp;</th>
              <th>
                <Changelog
                  by="division_id"
                  :value="division.id"
                  :status="timeline.status"
                  :fcid="forecast.id"
                  :field="timeline.field"
                  :useClientGroups="false"
                  @changeparams="onTimelineParamsChange"
                >
                  <v-btn icon small
                    ><v-icon small>mdi-timeline-text-outline</v-icon></v-btn
                  >
                </Changelog>
              </th>
            </tr>
          </thead>

          <template v-for="(g, index) in groupedProjects">
            <thead class="group-header" :key="`thead-${index}`" :class="{del: markForDeletion(g)}" v-if="g.projects.length">
              <tr>
                <td colspan="15" @click="onClickOutside"></td>
              </tr>
              <tr>
                <th colspan="2" class="left">
                  <v-icon
                    class="mr-2"
                    ripple="false"
                    dense
                    @click="toggleGroup($event, g.id)"
                    >{{
                      g.collapsed ? "mdi-chevron-right" : "mdi-chevron-down"
                    }}</v-icon
                  >
                  <span :title="`Client ID: ${g.id}`">{{ g.groupName }}</span>
                </th>
                <th class="sep">
                  <PieChart
                    :data="projects.filter((p) => p.budget.client_id === g.id)"
                    :localValues="true"
                    :label="g.groupName"
                  />
                </th>
                <th
                  class="sum right"
                  v-for="f in $store.state.budget.fields"
                  :key="f.key"
                  :class="{
                    zero: fromLocale(calculateClientColumn(g.id, f.key)) === 0 && f.key !== 'total_wo_fk',
                    sep: f.endOfGroup,
                  }"
                >
                  <span v-if="f.sumup"
                    >{{ calculateClientColumn(g.id, f.key)
                    }}<span class="suffix disabled" v-html="f.suffix"></span
                  ></span>
                </th>
                <th>&nbsp;</th>
                <th>
                  <Changelog
                    by="client_id"
                    :value="g.id"
                    within="division_id"
                    :withinValue="division.id"
                    :status="timeline.status"
                    :fcid="forecast.id"
                    :field="timeline.field"
                    @changeparams="onTimelineParamsChange"
                    :useClientGroups="false"
                  >
                    <v-btn icon small style="opacity: 0.66"
                      ><v-icon small>mdi-timeline-text-outline</v-icon></v-btn
                    >
                  </Changelog>
                </th>
              </tr>
            </thead>

            <tbody :key="`tbody-${index}`" :class="{ hidden: g.collapsed }">
              <tr
                v-for="p in g.projects"
      
                :key="p.id"
                :class="{ del: p.deleted_at, transfer: p.transfer, hidden: p.parent_id === 0 }"
              >
                <td>
                  <BudgetContextMenu
                    v-if="permissions.FC_DIVISION_WRITE"
                    :project="p"
                    @click="onContextMenuClick"
                  />
                  <span
                    :title="`Project ID: ${p.id}, Budget ID: ${p.budget.id}`"
                    style="margin-left: 0.35em"
                    >{{ p.budget.title }}</span
                  >
                </td>
                <td>
                  {{
                    getAttributeName(p.budget.attribute_id)
                  }}
                </td>

                <td class="center sep">
                  <v-icon size="16" :color="getStatus(p.budget.status).color">{{
                    getStatus(p.budget.status).icon
                  }}</v-icon>
                </td>

                <td
                  v-for="f in $store.state.budget.fields"
                  :key="f.key"
                  class="right has-input"
                  :class="{
                    sep: f.endOfGroup,
                    fremdkosten: f.key === 'fremdkosten',
                  }"
                  :id="`${f.key}_${p.id}`"
                  :title="getSavedValue(f.key, p.id)"
                >
                  <Costs
                    v-if="
                      f.key === 'fremdkosten' && permissions.FC_DIVISION_WRITE
                    "
                    :proj="p"
                    @accept="calculateRow(p.id)"
                  >
                    <v-icon
                      v-if="p.budget.costs && p.budget.costs.length > 0"
                      small
                      class="prefix"
                    >
                      mdi-numeric-{{ p.budget.costs.length }}-box-outline
                    </v-icon>
                    <input
                      type="text"
                      :class="{
                        zero: fromLocale(p.budget[f.key]) === 0,
                      }"
                      v-model.lazy="p.budget[f.key]"
                      readonly
                    />
                  </Costs>
                  <input
                    v-else
                    type="text"
                    @click.stop="setFocus($event)"
                    @dblclick.stop="dblclick($event)"
                    @blur="unfocus(p.id, $event)"
                    @keyup.tab="unfocus(p.id, $event)"
                    @keyup.enter="unfocus(p.id, $event)"
                    @keyup.up="unfocus(p.id, $event)"
                    @keyup.down="unfocus(p.id, $event)"
                    @keyup.right="unfocus(p.id, $event)"
                    @keyup.left="unfocus(p.id, $event)"
                    :class="{
                      zero: fromLocale(p.budget[f.key]) === 0,
                    }"
                    v-model.lazy="p.budget[f.key]"
                    :disabled="f.calculated || !permissions.FC_DIVISION_WRITE"
                  />

                  <span
                    class="suffix"
                    :class="{
                      zero: ['0.00', '0', 0].indexOf(p.budget[f.key]) > -1,
                    }"
                    v-html="f.suffix"
                  ></span>
                </td>

                <td class="has-input">
                  <input
                    type="text"
                    :title="p.budget.comment"
                    style="
                      text-align: left;
                      font-weight: normal;
                      min-width: 100px;
                      padding-right: 0.5em;
                    "
                    @click.stop="setFocus($event)"
                    @dblclick.stop="dblclick($event)"
                    @blur="unfocus(p.id, $event)"
                    @keyup.tab="unfocus(p.id, $event)"
                    @keyup.enter="unfocus(p.id, $event)"
                    @keyup.up="unfocus(p.id, $event)"
                    @keyup.down="unfocus(p.id, $event)"
                    @keyup.right="unfocus(p.id, $event)"
                    @keyup.left="unfocus(p.id, $event)"
                    v-model.lazy="p.budget.comment"
                    :disabled="!permissions.FC_DIVISION_WRITE"
                  />
                </td>
                <td class="center initials">
                  <History
                    :project="p"
                    :with_live="selected.snapshot === null"
                    v-if="p.budget.initials"
                  >
                    {{ p.budget.initials }}
                  </History>

                  <v-icon
                    v-else
                    size="18"
                    style="color: dodgerblue"
                    @click="undo(p.id)"
                    >mdi-reply-circle</v-icon
                  >
                </td>
              </tr>
              <tr class="footer">
                <td colspan="15" @click="onClickOutside">
                  <!-- <ProjectForm
                    @accept="onAccept"
                    :projectdata="{
                      forecast_id: forecast.id,
                      division_id: division.id,
                      budget: { client_id: g.id },
                    }"
                    v-if="permissions.FC_DIVISION_WRITE"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </ProjectForm> -->
                   <ClientContextMenu
                    v-if="permissions.FC_DIVISION_WRITE"
                    :client="g"
                    :projectdata="{
                      forecast_id: forecast.id,
                      division_id: division.id,
                      budget: { client_id: g.id },
                    }"
                    :timeline="timeline"
                    @click="onClientContextMenuClick"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </table>
        <v-banner v-else-if="isLocked(division, forecast.fy)" class="mt-4" color="warning">
            Diese Unit führt in {{ forecast.fy }} kein Budget.
        </v-banner>
        <v-banner v-else class="mt-4" color="info">
          Es sind keine Kampagnen in dieser Unit angelegt.
          <span v-if="permissions.FC_DIVISION_WRITE"
            >
            <span v-if="forecast.active">Klicke unten auf das Plus-Symbol, um eine Kampagne anzulegen.</span>
            </span
          >
        </v-banner>

        <v-footer padless min-height="96" color="#fff" fixed elevation="4">
          <v-row>
            <v-col cols="2" align-self="center">
              <ProjectForm
                v-if="permissions.FC_DIVISION_WRITE"
                @accept="onAccept"
                :projectdata="{
                  forecast_id: forecast.id,
                  division_id: division.id,
                }"
              >
                <v-btn
                  color="primary"
                  elevation="6"
                  fab
                  large
                  class="fab-new"
                  style="bottom: 1rem"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </ProjectForm>


             <ImportFromForecast
                v-if="allowImport && permissions.FC_DIVISION_WRITE && permissions.FC_DIVISION_COMMIT"
                @accept="onAccept"
                :query="{
                    fcid: forecast.id,
                    division: division.id,
                    snapshot: selected.snapshot,
                    clients: (projects.filter(p => p.parent && p.parent.forecast !== forecast.id) || []).map((p) => p.budget.client_id),
                    projects: (projects.filter(p  => p.parent && p.parent.forecast !== forecast.id) || []).map((p) => p.parent.id).filter(x => x),
                    budgets: (projects.filter(p => p.parent && p.parent.forecast !== forecast.id && p.importWithBudget) || []).map((p) => p.parent.id).filter(x => x)
                  }">
                  <v-btn color="primary" style="margin-left:120px">Daten-Übernahme...</v-btn>
             </ImportFromForecast>
             
            </v-col>

            <v-col cols="8" align="center" align-self="center">
              <div v-if="permissions.FC_DIVISION_COMMIT" class="py-4">
                <v-row v-if="snapshot" align="center" justify="center">
                  <v-col v-if="snapshotCommitted">
                    <v-icon large color="success" class="mr-2"
                      >mdi-check-circle</v-icon
                    >

                    Zahlen "{{ snapshot.title }}" gemeldet am
                    {{ d2s(snapshotCommitted.created_at, "medium") }} von
                    {{ snapshotCommitted.created_by }}.
                  </v-col>
                  <v-col v-else-if="snapshot.meta.is_due">
                    <v-icon large color="error" class="mr-2"
                      >mdi-calendar-alert</v-icon
                    >
                    Du bist spät dran! Deine Zahlen für "{{ snapshot.title }}"
                    sollten bis spätestens&nbsp;&nbsp;<span
                      style="font-weight: bold; text-decoration: underline"
                      >{{ d2s(snapshot.due_date, "medium") }}</span
                    >&nbsp;&nbsp; an das Controlling gemeldet werden.
                  </v-col>
                  <v-col v-else>
                    <v-icon large class="mr-2">mdi-calendar-cursor</v-icon>
                    Melde deine Zahlen für "{{ snapshot.title }}" bis
                    spätestens&nbsp;&nbsp;<span
                      style="font-weight: bold; text-decoration: underline"
                      >{{ d2s(snapshot.due_date, "medium") }}</span
                    >&nbsp;&nbsp; an das Controlling.
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="commit"
                      :label="trl('snapshot_commit')"
                      color="primary"
                      class="d-inline-flex ml-12"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row justify="center" v-else>
                  {{ trl("snapshot_none") }}
                </v-row>
              </div>
              <div v-else>
                <div v-if="selected.snapshot !== null">
                  Du betrachtest Snapshot {{ snapshotMeta }}.
                  <span v-if="forecast.active">Klicke
                    <a href="javascript:void()" @click="navSnapshotLive">hier</a>,
                  um zum aktuellen Stand zurückzukehren.</span>
                </div>
                <div v-else>
                  <span v-if="forecast.active && !permissions.FC_DIVISION_WRITE"
                    >Du hast Leserechte auf diese Budgetübersicht.</span
                  >
                  <span v-else-if="!forecast.active">
                    Die Bearbeitung dieses Forecasts wurde vom Controlling gesperrt.
                  </span>
                </div>
              </div>
            </v-col>

            <v-col cols="2" align="center" class="pr-12" align-self="center">
              <v-badge
                :content="getChangedRows().length"
                :value="getChangedRows().length"
                color="#1e90ff"
                overlap
                bordered
              >
                <v-btn
                  v-if="
                    permissions.FC_DIVISION_WRITE ||
                    permissions.FC_DIVISION_COMMIT
                  "
                  color="primary"
                  :disabled="getChangedRows().length === 0 && !commit"
                  @click="saveBudget"
                  >{{ getSubmitButtonLabel() }}
                </v-btn>
              </v-badge>
            </v-col>
          </v-row>
        </v-footer>
      </div>
    </div>
  </v-container>
</template>

<script>
import { trl, ls_get, ls_set, getClientName, getAttributeName, numerus } from "@/utils/strings";
import { d2s, isLocked } from "@/utils/dates";
import { fromLocale, toLocale } from "@/utils/numbers";
import confirm from "@/components/modals/Confirm.vue";
import ProjectForm from "@/components/forms/Project";
import ImportFromForecast from "@/components/forms/Import";
import Costs from "@/components/forms/Costs";
import History from "@/components/forms/History";
import BudgetContextMenu from "@/components/BudgetContextMenu";
import ClientContextMenu from "@/components/ClientContextMenu";
import DivisionUsersModal from "@/components/modals/DivisionUsers";
import Changelog from "@/components/Changelog";
import Download from "@/components/modals/Download";
import PieChart from "@/components/PieChart";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "Budget",
  data() {
    return {
      loading: true,
      commit: false,
      allowImport: false,
      headline: "",
      forecast: {},
      snapshot: null,
      division: {},
      groups: [],
      projects: [],
      last: [],
      saved: [],
      permissions: {},
      info: "",
      insideCell: false,
      selected: {
        forecast: this.$store.state.activeForecastId,
        snapshot: null,
      },
      timeline: {
        status: [2, 1, 0],
        field: "total_wo_fk",
      },
    };
  },
  methods: {
    setPermissions(divisionId) {
      let perms = JSON.parse(JSON.stringify(this.current.permissions));
      let permissions = {};

      Object.keys(perms)
        .filter((key) => key.substr(0, 12) === "FC_DIVISION_")
        .forEach((p) => {
          if (typeof perms[p] === "boolean") {
            
            permissions[p] = perms[p];
            return;
          }

          let divisionInPermissions = perms[p].filter(
            (item) => item.division_id === divisionId
          );
          permissions[p] = divisionInPermissions.length === 1;
        });

      if (this.selected.snapshot || this.forecast.active !== 1) {

        permissions["FC_DIVISION_WRITE"] = false;
        permissions["FC_DIVISION_COMMIT"] = false;
      }

      this.permissions = permissions;
    
      return permissions;
    },
    onClickOutside() {
      document
        .querySelectorAll("table.budget tr")
        .forEach((tr) => tr.classList.remove("has-focus"));

      document
        .querySelectorAll("table.budget td")
        .forEach((td) => td.classList.remove("has-focus"));
    },
    setForecast(id) {
      //this.$store.commit("setForecast", id);
      this.forecast = this.forecasts.find((fc) => fc.id === id);

      // Daten-Übernahme nur, wenn der FC aktiv ist und es noch mindestesn einen weiteren sichtbaren gibt, aus dem Daten uebernommen werden koennen 
      this.allowImport = this.forecast.active && this.forecasts.filter(f => f.vis === 1).length > 1;
      if (this.allowImport && this.forecast.snapshots){
        //  Daten-Uebernahme nur moeglich, wenn der FC noch keinen durchs Controlling geschlossenen Snapshot aufweist
        this.allowImport = this.forecast.snapshots.map(s => s.closed_at).filter(x => x).length === 0;
      } 
      this.projects = [];
      this.saved = [];
      this.fetchBudget();
    },
    setDivision(id) {
      this.division = this.divisions.find((d) => d.id === id && !d.deleted_at);

      if (!this.division) {
        this.$router.replace({ name: "NotFound" });
        return;
      }

      this.headline = this.division.name;
      this.info = "";
      (this.groups = []), (this.projects = []);
      this.deletes = [];
      this.saved = [];

      // schoener als ein redirect waere ein Hinweis, dass die Unit in diesem Jahr kein Budget fuehrt
      if (isLocked(this.division, this.forecast.fy)) {
          return;
      }

      if (this.$route.query.forecast_id) {
        this.setForecast(this.$route.query.forecast_id);
      }

      if (this.$route.query.snapshot_id) {
        this.selected.snapshot = this.$route.query.snapshot_id;
      } else if (!this.forecast.active) {
        this.selected.snapshot = this.forecast.snapshots.at(-1).id;
      } else {
        this.selected.snapshot = null;
      }

      this.setPermissions(id);

      if (!this.permissions.FC_DIVISION_READ) {
        this.headline = "Access Denied";
        this.loading = false;
        return;
      }
      document.title = `${process.env.VUE_APP_TITLE_SHORT} - Budget ${this.division.name}`;
      this.loading = true;
      this.fetchBudget();
    },
    getSnapshot() {
      if (!this.permissions.FC_DIVISION_COMMIT) {
        // wenn der User keine Zahlen committen darf, interessiert uns das nicht.
        return null;
      }

      let snapshots = this.forecast.snapshots.filter(
        (s) => s.meta.allow_commit
      );

      if (snapshots.length === 0) {
        return null;
      } else if (snapshots.length === 1) {
        return snapshots[0];
      } else {
        alert("Warnung: Multiple snapshots detected!");
        return null;
      }
    },
    getSnapshotMeta(snapshot_id, division_id) {
      let snap = this.forecast.snapshots.find((s) => s.id === snapshot_id);
    },
    async navSnapshot() {
      // let query = {};
      // if (this.selected.snapshot) {
      //   query.snapshot_id = this.selected.snapshot;
      // }

      // this.$router.push({
      //   name: "BudgetDivision",
      //   params: {
      //     tenant: this.$route.params.tenant,
      //     id: this.$route.params.id,
      //   },
      //   query: query,
      // });
      //this.$router.replace({ query: queries });

      let confirmed = true;

      if (this.getChangedRows().length > 0) {
        confirmed = await this.$refs.confirm.open(
          "Ungespeicherte Änderungen",
          "Willst du einen anderen Snapshot aufrufen, ohne die Änderungen zu speichern?",
          {
            confirmLabel: "Nicht speichern",
            cancelLabel: "zurück",
            color: "primary",
            width: 500,
          }
        );
      }

      if (confirmed) {
        this.setPermissions(this.division.id);
        this.fetchBudget();
      }
    },
    navSnapshotLive() {
      this.selected.snapshot = null;
      this.navSnapshot();
    },
    onAccept(data) {
      // added project from form child component
      this.findPlaceholder(data[0].budget.client_id);
      this.projects = [...this.projects, ...data];

      this.last = [...this.last, ...JSON.parse(JSON.stringify(data))];



      this.info = this.changes;
    },
    onAccept2(obj) {
      // updated project from form child component
      let index = this.projects.findIndex((item) => item.id === obj.id);
      if (index > -1) {
        this.projects.splice(index, 1, obj);
      }

      this.calculateRow(obj.id);
      this.info = this.changes;
    },
    onTimelineParamsChange(obj) {
      this.timeline = obj;
    },
    onContextMenuClick(elem) {
      switch (elem.action) {
        case "edit":
          this.onAccept2(elem.project);
          break;
        case "delete":
          this.softDelete(elem.project.id);
          break;
        case "duplicate":
          const dupe = JSON.parse(JSON.stringify(elem.project));
          dupe.id = "_" + Math.random() * Date.now();
          delete dupe.budget.initials;
          this.projects.push(dupe);

          this.last.push(JSON.parse(JSON.stringify(dupe)));
          this.info = this.changes;
          break;
        case "transfer":
          let projects = [
            this.projects.find((item) => item.id === elem.data.transferProject),
          ];

          if (elem.data.transferContent === "client_campaigns") {
            projects = this.projects.filter(
              (item) => item.budget.client_id === projects[0].budget.client_id
            );
          }

          projects.forEach((p) => {
            p.transfer = elem.data;
            p.deleted_at = elem.data.transferBudget;
          });

          break;
        default:
          alert(elem.action);
      }
    },
    onClientContextMenuClick(elem){
        switch (elem.action) {
        case "insert":
          this.onAccept(elem.project);
          break;
        case "delete":
          elem.group.projects.forEach(p => this.softDelete(p.id));
          break;
        case "transfer":
          let  projects = this.projects.filter(
              (item) => item.budget.client_id === elem.data.transferClient
            );

          let isNotTransferrable = [];

          projects.forEach((p) => {
            if (p.id.toString().substr(0, 1) === "_") {
              isNotTransferrable.push(p);
              return;
            }
            p.transfer = elem.data;
            p.deleted_at = elem.data.transferBudget;
          });

          if (isNotTransferrable.length > 0) {
            this.$store.commit("alert", {
                title: "Hinweis",
                text: "Neu angelegte und noch nicht gespeicherte Kampagnen sind nicht übertragbar. " + numerus('? Kampagne|n wurde|n nicht markiert.', isNotTransferrable.length),
              });
          }


          break;
        case "timeline":
          this.timeline = elem.data;
          break;
        }
    },
    toggleGroup($event, groupId) {
      const tbody = $event.target.closest(".group-header").nextSibling;
      const isHidden = tbody.classList.contains("hidden");
      const chevron = isHidden ? "mdi-chevron-down" : "mdi-chevron-right";

      $event.target.classList.remove("mdi-chevron-down", "mdi-chevron-right");
      $event.target.classList.add(chevron);
      isHidden
        ? tbody.classList.remove("hidden")
        : tbody.classList.add("hidden");

      let toggleState = ls_get("togglestate", []);

      let toggleStateDivision = toggleState.find(
        (item) => item.division == this.division.id
      );

      if (!toggleStateDivision) {
        if (isHidden) {
          toggleState.push({ division: this.division.id, groups: [groupId] });
        }
      } else {
        if (isHidden) {
          toggleStateDivision.groups.push(groupId);
        } else {
          let index = toggleStateDivision.groups.indexOf(groupId);
          toggleStateDivision.groups.splice(index, 1);
        }
      }

      ls_set("togglestate", toggleState);
    },
    toggleAll() {
      let ctrAll = this.groups.length;
      let ctrCollapsed = this.groups.filter((g) => g.collapsed).length;
      let collapseAll = ctrCollapsed < ctrAll;
      this.groups.map((g) => (g.collapsed = collapseAll));

      let toggleState = ls_get("togglestate", []);
      let toggleStateDivision = toggleState.find(
        (item) => item.division == this.division.id
      );

      let groups = [];

      if (!collapseAll) {
        groups = this.groups.map((g) => g.id);
      }

      if (toggleStateDivision) {
        toggleStateDivision.groups = groups;
      } else {
        toggleState.push({ division: this.division.id, groups: groups });
      }

      ls_set("togglestate", toggleState);

      return;
      let toggleButtons = document.querySelectorAll(
        "table.budget thead.group-header > tr:nth-child(2) > th:nth-child(1) > button"
      );

      if (toggleButtons.length < 3) return;

      toggleButtons.forEach((btn) => {
        btn.click();
      });
    },
    getStatus(num) {
      return this.$store.state.budget.status.find((item) => item.value === num);
    },
    getSavedValue(key, id) {
      if (key === "fremdkosten") {
        return "Klicke ins Feld, um die Fremdkosten zu bearbeiten.";
      }
      let current = this.projects.find((item) => item.id === id);
      let saved = this.saved.find((item) => item.id === id);
      if (!saved) return;

      if (current.budget[key] === saved.budget[key]) {
        return;
      }

      let str = "war " + saved.budget[key];

      // if (
      //   !this.$store.state.budget.fields.find((f) => f.key === key).calculated
      // ) {
      //   str += ". Gib 'x' ein, um zurückzusetzen.";
      // }

      return str;
    },
    getSubmitButtonLabel() {
      if (!this.permissions.FC_DIVISION_COMMIT) {
        return trl("save changes");
      }

      if (!this.permissions.FC_DIVISION_WRITE) {
        return trl("budget_commit");
      }

      if (this.commit) {
        return this.getChangedRows().length === 0
          ? trl("budget_commit")
          : trl("budget_save_and_commit");
      }

      return trl("save changes");
    },
    undo(id) {
      let saved = this.saved.find((item) => item.id === id);

      if (!saved) {
        this.softDelete(id);
        return;
      }

      let index = this.projects.findIndex((item) => item.id === id);
      if (index > -1) {
        this.projects.splice(index, 1, JSON.parse(JSON.stringify(saved)));
        this.calculateRow(id);
      }
    },
    calculateColumn(key) {
      let total = 0;
      this.projects.forEach((p) => {
        if (p.deleted_at) return;

        total += fromLocale(p.budget[key]) || 0;
      });

      return toLocale(total, 2);
    },
    calculateClientColumn(clientId, key) {
      let total = 0;
      this.projects
        .filter((p) => p.budget.client_id === clientId)
        .forEach((p) => {
          if (p.deleted_at) return;
          total += fromLocale(p.budget[key]) || 0;
        });

      return toLocale(total, 2);
    },
    calculateRow(id) {
      let current = this.projects.find((item) => item.id === id).budget;
      let last = this.last.find((item) => item.id === id)
        ? this.last.find((item) => item.id === id).budget
        : {};
      let saved = this.saved.find((item) => item.id === id)
        ? this.saved.find((item) => item.id === id).budget
        : {};

      // alle aktuellen Werte in echte Zahlen konverieren
      this.$store.state.budget.fields.forEach((f) => {
        current[f.key] = fromLocale(current[f.key]);
        //if (current[f.key] === "") current[f.key] = 0;
        // let tmp = current[f.key].toString().toLowerCase();
        // tmp = tmp.replace(/\,/, ".");
        // tmp = parseFloat(tmp);
        // let char1 = tmp.substr(0, 1);

        // switch (char1) {
        //   case "x":
        //     current[f.key] = saved[f.key];
        //     break;
        //   case "+":
        //     current[f.key] =
        //       parseFloat(last[f.key]) + parseFloat(tmp.substr(1));
        //     break;
        //   case "-":
        //     //current[f.key] = last[f.key] - parseFloat(tmp.substr(1));
        //     break;
        //   default:
        //     current[f.key] = parseFloat(tmp);
        // }
      });

      // Spalten berechnen
      current.agenturnetto = current.kundennetto * ((100 - current.ae) / 100);

      current.honorar_basis =
        (current[current.honorar_basis_field] * current.honorar_basis_percent) /
        100;

      current.total =
        current.honorar_basis + current.honorar_sokos + current.income_other;

      current.total_wo_fk = current.total - current.fremdkosten;

      // Zahlen in lokale Strings wandeln
      this.$store.state.budget.fields.forEach((f) => {
        // farbliche Markierung
        const td = document.getElementById(f.key + "_" + id);
        td.classList.remove("red", "green", "lighten-5");

        if (!saved[f.key]) {
          if (current[f.key] > 0) {
            td.classList.add("green", "lighten-5");
          }
        } else if (fromLocale(saved[f.key]) < current[f.key]) {
          td.classList.add("green", "lighten-5");
        } else if (fromLocale(saved[f.key]) > current[f.key]) {
          td.classList.add("red", "lighten-5");
        }

        // in lokalen String wandeln
        current[f.key] = toLocale(current[f.key], f.round);
      });

      let index = this.last.findIndex((item) => item.id === id);
      if (index > -1) {
        this.last.splice(
          index,
          1,
          JSON.parse(
            JSON.stringify(this.projects.find((item) => item.id === id))
          )
        );
      }

      this.info = this.changes;
    },
    setFocus(evt) {
      if (this.insideCell === evt.target) {
        return;
      }

      this.insideCell = false;
      evt.target.select();

      document
        .querySelectorAll("table.budget tr")
        .forEach((tr) => tr.classList.remove("has-focus"));

      document
        .querySelectorAll("table.budget td")
        .forEach((td) => td.classList.remove("has-focus"));

      evt.target.closest("tr").classList.add("has-focus");
      evt.target.closest("td").classList.add("has-focus");
    },
    dblclick(evt) {
      this.insideCell = evt.target;
      evt.target.select();
      evt.target.classList.add("inside-cell");

      document
        .querySelectorAll("table.budget tr")
        .forEach((tr) => tr.classList.remove("has-focus"));

      document
        .querySelectorAll("table.budget td")
        .forEach((td) => td.classList.remove("has-focus"));

      evt.target.closest("tr").classList.add("has-focus");
      evt.target.closest("td").classList.add("has-focus");
    },
    unfocus(id, evt) {
      if (evt.type === "blur") {
        this.insideCell = false;
        evt.target.classList.remove("inside-cell");
        this.calculateRow(id);
        return;
      }

      if (evt.type === "keyup") {
        // fuer links-rechts navi
        let rowInputs = [
          ...evt.target
            .closest("tr")
            .querySelectorAll("input:not(:disabled,[readonly])"),
        ];
        let colIndex = rowInputs.indexOf(evt.target);

        // fuer down-up navi
        let rowsInTable = [
          ...evt.target
            .closest("table")
            .querySelectorAll("tbody > tr:not(.footer)"),
        ];
        let rowIndex = rowsInTable.indexOf(evt.target.closest("tr"));

        let customEvent = new Event("click");

        if (["Enter", "ArrowDown"].indexOf(evt.key) > -1) {
          this.insideCell = false;
          evt.target.classList.remove("inside-cell");

          this.calculateRow(id);
          if (rowIndex < rowsInTable.length - 1) {
            [
              ...rowsInTable[rowIndex + 1].querySelectorAll(
                "input:not(:disabled,[readonly])"
              ),
            ][colIndex].dispatchEvent(customEvent);
          } else {
            evt.target.blur();
            [
              ...rowsInTable[rowIndex].querySelectorAll(
                "input:not(:disabled,[readonly])"
              ),
            ][colIndex].dispatchEvent(customEvent);
          }
          return;
        }

        if (evt.key === "ArrowUp") {
          this.insideCell = false;
          evt.target.classList.remove("inside-cell");
          this.calculateRow(id);
          if (rowIndex > 0) {
            [
              ...rowsInTable[rowIndex - 1].querySelectorAll(
                "input:not(:disabled,[readonly])"
              ),
            ][colIndex].dispatchEvent(customEvent);
          } else {
            evt.target.blur();
            [
              ...rowsInTable[0].querySelectorAll(
                "input:not(:disabled,[readonly])"
              ),
            ][colIndex].dispatchEvent(customEvent);
          }
          return;
        }

        if (evt.key === "Tab") {
          this.insideCell = false;
          evt.target.classList.remove("inside-cell");
          this.calculateRow(id);
          if (colIndex < rowInputs.length) {
            rowInputs[colIndex].dispatchEvent(customEvent);
          }
          return;
        }

        if (this.insideCell) return;

        if (evt.key === "ArrowRight") {
          this.insideCell = false;
          evt.target.classList.remove("inside-cell");
          this.calculateRow(id);

          if (colIndex < rowInputs.length - 1) {
            rowInputs[colIndex + 1].dispatchEvent(customEvent);
          } else {
            evt.target.blur();
            rowInputs[colIndex].dispatchEvent(customEvent);
          }
          return;
        }

        if (evt.key === "ArrowLeft") {
          this.insideCell = false;
          evt.target.classList.remove("inside-cell");
          this.calculateRow(id);

          if (colIndex > 0) {
            rowInputs[colIndex - 1].dispatchEvent(customEvent);
          } else {
            evt.target.blur();
            rowInputs[0].dispatchEvent(customEvent);
          }
          return;
        }
      }
    },
    getChangedRows() {
      let changes = [];
      this.projects.forEach((p, index) => {
        let saved = this.saved.find((item) => item.id === p.id); //this.saved[index];

        if (!saved) {
          changes.push(p);
          return;
        }

        let temp = JSON.parse(JSON.stringify(p));
        temp.rowIcon = saved.rowIcon;
        temp.budget.initials = saved.budget.initials;

        if (JSON.stringify(temp) === JSON.stringify(saved || {})) {
          p.budget.initials = saved.budget.initials;
          return;
        }

        delete p.budget.initials;
        changes.push(p);
      });

      return JSON.parse(JSON.stringify(changes));
    },
    softDelete(id) {
      let index = this.projects.findIndex((p) => p.id === id);
    
      if (this.projects[index].deleted_at) {
        // undo mark for deletion: restore delete info
        this.projects[index].deleted_at = null;
        return;
      }

      if (this.projects[index].id.toString().substr(0, 1) === "_") {
        //this.projects[index].deleted_at = true;
        this.projects.splice(index, 1);
      } else {
        this.projects[index].deleted_at = true;
      }
      
      this.info = this.changes;
    },
    markForDeletion(obj){
        return obj.projects.length === obj.projects.filter(p => p.deleted_at && !p.transfer).length;
    },
    findPlaceholder(clientId){
        // wenn ein Kunde "OHNE Kampagne" der Budgetuebersicht hinzugefuegt wird,
        // generieren wir eine unsichtbare Platzhalter-Kampagne mit der parent_id "0" (nicht NULL!).
        // Sobald mit 'onAccept' für den Kunden eine _echte_ Kampagne angelegt wird, loeschen wir den Platzhalter wieder
        let placeholder = this.projects.find(p => p.parent_id === 0 && p.budget.client_id === clientId);
        if (placeholder) {
          placeholder.deleted_at = true;
        }
    },
    setColumnWidths() {
      document
        .querySelectorAll(
          "table.budget > thead:first-Child > tr:first-Child th"
        )
        .forEach((th) => {
          th.style.width = th.offsetWidth + "px";
        });
    },
    async fetchBudget() {
        if (!this.forecast.id && this.forecasts_active.length > 0) {
         
        this.setForecast(this.$store.state.activeForecastId);
        return;
      }
      // if (!this.forecast.id && this.forecasts_active.length === 1) {
      //   this.setForecast(this.forecasts_active[0].id);
      //   return;
      // }

      if (!this.forecast.id || !this.division.id) {
        this.loading = false;
        return;
      }

      let params = {
        fcid: this.forecast.id,
        division: this.division.id,
      };

      if (this.selected.snapshot) {
        params.snapshot = this.selected.snapshot;
      }

      const projects = await this.$store.dispatch("req", {
        route:
          "budget?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });


      projects.forEach((p) => {
        p.rowIcon = "mdi-dots-horizontal";
        p.deleted_at = null;
        this.$store.state.budget.fields.forEach(
          (f) => (p.budget[f.key] = toLocale(p.budget[f.key], f.round))
        );

        if (p.budget.costs) {
          p.budget.costs.forEach((c) => (c.value = toLocale(c.value, 2)));
        }
      });

      this.groups = [];
      this.projects = JSON.parse(JSON.stringify(projects));
      this.last = JSON.parse(JSON.stringify(this.projects));
      this.saved = JSON.parse(JSON.stringify(this.projects));

      this.snapshot = this.getSnapshot();
      this.loading = false;

      this.$nextTick(() => {
        document
          .querySelectorAll("td")
          .forEach((td) => td.classList.remove("red", "green", "lighten-5"));

        this.setColumnWidths();
        // if (this.groups.length > 3) {
        //   this.groups.map((g) => (g.collapsed = true));
        // }
      });
    },
    async saveBudget() {
      let showNotCommittedWarning = false;
      let data = {};

      if (
        this.commit &&
        this.snapshot.id &&
        this.permissions.FC_DIVISION_COMMIT
      ) {
        data = {
          snapshot_id: this.snapshot.id,
          division_id: this.division.id,
          projects: this.getChangedRows(),
        };
      } else {
        data.projects = this.getChangedRows();
        if (data.projects.length === 0) {
          alert("Nothing to save"); // tritt nicht auf, da Button disabled ist
          return;
        }
      }

      // if (this.allowImport) {
      //   let confirmed = await this.$refs.confirm.open(
      //   "Datenübernahme abgeschlossen?",
      //   "Sobald du deinen Forecast speicherst, wird die Option der Daten-Übernahme aus einem alten Forecast nicht mehr angeboten, und du kannst nur wie gewohnt über den \"+\"-Button unten links im Hauptfenster neue Kampagnen hinzufügen.",
      //   {
      //     confirmLabel: "Verstanden und speichern",
      //     cancelLabel: "Abbrechen",
      //     color: "primary",
      //     width: 500,
      //   }
      // );
      //   if (!confirmed) return;
      // }


      // remove row icon property that was added in fetchBudget
      data.projects.map((p) => {
        delete p.rowIcon;
        this.$store.state.budget.fields.forEach((f) => {
          p.budget[f.key] = fromLocale(p.budget[f.key]);
        });

        if (p.budget.costs) {
          p.budget.costs.forEach((c) => (c.value = fromLocale(c.value)));
        }
      });

      const dev = await this.$store.dispatch("req", {
        route: "budget",
        data: data,
      });
      this.info = "";

      if (this.snapshotCommitted && !this.commit) {
        showNotCommittedWarning = true;
      }

      if (
        this.commit &&
        this.snapshot.id &&
        this.permissions.FC_DIVISION_COMMIT
      ) {
        await this.$store.dispatch("req", {
          method: "GET",
          route: "forecasts",
          mutation: "forecasts",
        });
        this.commit = false;
        this.forecast = this.forecasts_active.find(
          (fc) => fc.id === this.forecast.id
        );
      }

      await this.fetchBudget();

      if (showNotCommittedWarning) {
        this.$store.commit("alert", {
          title: "Hinweis",
          text: "Deine Änderungen wurden gespeichert, aber nicht ans Controlling gemeldet.",
        });
      }
    },
    tableIndex(col, row) {
      const cells = document.querySelector(
        "table.budget >thead:nth-child(1) > tr:nth-child(1)"
      ).childNodes;
      cells.forEach((c) => c.classList.remove("columnHelper"));
      cells[col].classList.add("columnHelper");
    },
    trl,
    d2s,
    toLocale,
    fromLocale,
    getClientName,
    getAttributeName,
    isLocked
  },
  computed: {
    groupedProjects() {
      this.groups.map((g) => (g.projects = []));

      let uncollapsed = [];
      let toggleState = ls_get("togglestate", []);
      let toggleStateDivision = toggleState.find(
        (item) => item.division == this.division.id
      );

      if (toggleStateDivision) uncollapsed = toggleStateDivision.groups;

      this.projects.forEach((p) => {
        let clientName = this.getClientName(p.budget.client_id);
        let groupIndex = this.groups.findIndex(
          (item) => item.id === p.budget.client_id
        );

        if (groupIndex === -1) {
          this.groups.push({
            id: p.budget.client_id,
            groupName: clientName,
            projects: [],
            collapsed: uncollapsed.indexOf(p.budget.client_id) === -1,
          });
          groupIndex = this.groups.length - 1;
        }

        let projIndex = this.groups[groupIndex].projects.findIndex(
          (item) => item.id === p.id
        );

        if (projIndex === -1) {
          this.groups[groupIndex].projects.push(p);
        } else {
          this.groups[groupIndex].projects[projIndex] = p;
        }

        if (p.id.toString().substr(0, 1) === "_") {
          this.groups[groupIndex].collapsed = false;
        }
      });

      return this.groups.sort((a, b) => {
        let a_name = a.groupName.toLowerCase();
        let b_name = b.groupName.toLowerCase();
        return a_name == b_name ? 0 : b_name > a_name ? -1 : 1;
      });
    },
    snapshotCommitted() {
      return this.snapshot?.commits.find(
        (c) => c.division_id === this.division.id
      );
    },
    ...mapGetters([
      "current",
      "divisions",
      "clients",
      "attributes",
      "forecasts",
      "users",
      "me",
    ]),
    forecasts_active() {
      return this.forecasts.filter((fc) => fc.active === 1);
    },
    snapshotMeta() {
      if (!this.selected.snapshot) return "";

      let snap = this.forecast.snapshots.find(
        (s) => s.id === this.selected.snapshot
      );

      if (!snap) return;

      let str = snap.title;

      let commit = snap.commits.find((c) => c.division_id === this.division.id);

      if (commit) {
        str += ` vom ${d2s(commit.created_at, "short")}`;
      }

      return str;
    },
    mySnapshot() {
      if (!this.permissions.FC_DIVISION_COMMIT) {
        // wenn der User keine Zahlen committen darf, interessiert uns das nicht.
        return null;
      }

      let snapshots = this.forecast.snapshots.filter(
        (s) => DateTime.fromSQL(s.start_date) <= DateTime.now() && !s.closed_at
      );

      if (snapshots.length === 0) {
        return null;
      } else if (snapshots.length === 1) {
        return snapshots[0];
      } else {
        alert("Warnung: Multiple snapshots detected!");
        return null;
      }
    },
    changes() {
      return trl("unsaved changes", this.getChangedRows().length);
    },
  },
  components: {
    ProjectForm,
    ImportFromForecast,
    Costs,
    History,
    BudgetContextMenu,
    ClientContextMenu,
    PieChart,
    confirm,
    DivisionUsersModal,
    Download,
    Changelog,
  },
  created() {
    this.forecast = {};
    this.setForecast(this.$store.state.activeForecastId);
    this.setDivision(Number(this.$route.params.id));
  },
  async beforeRouteUpdate(to, from, next) {
    let confirmed = true;

    if (this.getChangedRows().length > 0) {
      confirmed = await this.$refs.confirm.open(
        "Ungespeicherte Änderungen",
        "Willst du diese Seite verlassen, ohne die Änderungen zu speichern?",
        {
          confirmLabel: "Nicht speichern",
          cancelLabel: "zurück",
          color: "primary",
          width: 500,
        }
      );
    }

    if (confirmed) {
      //this.forecast = {};
      this.setDivision(to.params.id);

      next();
    }
  },
  async beforeRouteLeave(to, from, next) {
    let confirmed = true;

    if (this.getChangedRows().length > 0) {
      confirmed = await this.$refs.confirm.open(
        "Ungespeicherte Änderungen",
        "Willst du diese Seite verlassen, ohne die Änderungen zu speichern?",
        {
          confirmLabel: "Nicht speichern",
          cancelLabel: "zurück",
          color: "primary",
          width: 500,
        }
      );
    }

    if (confirmed) next();
  },
};
</script>

<style lang="scss">
table.budget {
  &.simple {
    thead > tr {
      :nth-child(3),
      :nth-child(5),
      :nth-child(6),
      :nth-child(7),
      :nth-child(8),
      :nth-child(9) {
        display: none;
      }
    }

    tbody > tr {
      :nth-child(3),
      :nth-child(5),
      :nth-child(6),
      :nth-child(7),
      :nth-child(8),
      :nth-child(9) {
        display: none;
      }
    }
  }

  font-size: 13px;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0 auto 120px auto;

  .zero {
    opacity: 0;
  }

  .green.lighten-5,
  .red.lighten-5 {
    .zero {
      opacity: 1;
    }
  }

  .suffix {
    display: inline-block;
    padding: 0.2em 0.25em;
    color: #1e90ff;
    visibility: hidden;
    opacity: 0.75;
    right: 0.2rem;
  }

  th,
  td {
    padding: 0.2em 0.25em 0.1em 0.75em;
    white-space: nowrap;
    min-width: 72px;

    &:last-child {
      // initials
      min-width: auto;
    }

    &.has-input {
      padding: 0em;
      margin: 0;
      .suffix {
        position: absolute;
      }
    }

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.initials {
      span {
        opacity: 0.5;
      }
      font-size: 11px;
      width: 36px;
      text-align: center;
    }

    &.sep {
      border-right: 1px solid #fff;
    }
  }

  thead {
    background-color: #cfcfcf;

    &:first-child {
      border-top: none;
      position: sticky;
      top: 64px;
      z-index: 2;
    }

    .zero {
      opacity: 1;

      & > * {
        opacity: 1;
      }
    }

    .columnHelper {
      color: dodgerblue;
    }

    &.group-header {
      background-color: #e6e6e6;

      &.del{
        background-color:#e8acac;
      }

      tr:first-child {
        background-color: #f5f5f5;
        td {
          height: 16px;
        }
      }

      th {
        vertical-align: middle;
      }

      .sum:not(.zero) {
        span {
          opacity: 0.67;
        }
      }

      .sum.zero {
        opacity: 1;
        span {
          opacity: 0;
        }
      }
    }
  }

  tbody {
    tr td {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    td {
      border: 1px solid #e9e9e9;
      position: relative;
    }

    tr:hover {
      .zero,
      .zero + .suffix {
        opacity: 0.33;
      }

      .suffix {
        visibility: visible;
      }
    }

    tr.has-focus {
      .zero {
        transition-duration: 250ms;
        opacity: 1;
      }

      .suffix {
        transition-duration: 250ms;
        visibility: visible;
      }

      td {
        //border-bottom: 1px solid dodgerblue !important;

        &.green.lighten-5,
        &.red.lighten-5 {
          transition-duration: 250ms;
        }

        &.has-focus {
          &.green.lighten-5,
          &.red.lighten-5 {
            border: 1px solid dodgerblue !important;
          }
        }
      }
    }

    td.has-focus {
      border: 1px solid dodgerblue !important;
    }

    tr.del {
      font-style: italic;
      color: rgb(211, 92, 92);
      background-color: rgb(245, 206, 206);

      input[type="text"] {
        color: rgb(211, 92, 92);
        font-style: normal;
        text-decoration: line-through;
      }
    }

    tr.transfer {
      font-style: italic;
      color: rgb(65, 65, 65);
     

      td{
        background-color: rgba(187, 175, 165, .7) !important;
      }

      input[type="text"] {
        color: dodgerblue;
        font-style: normal;
      }
    }

    tr.hidden {
      display:none;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }

      :hover {
        border-bottom-color: transparent;
      }
    }

    &.hidden {
      display: none;
    }
  }

  input[type="text"] {
    box-sizing: border-box;
    text-align: right;
    width: 100%;
    height: 100%;
    color: dodgerblue;
    font-weight: bold;
    cursor: default;
    margin: 0;
    padding: 0.25em 1.75em 0.25em 0.5em;

    &:focus {
      outline: none;
      caret-color: transparent;
    }

    &::selection {
      background: transparent;
    }

    &.inside-cell {
      caret-color: auto;

      &::selection {
        background: rgb(197, 212, 228);
      }
    }

    &:disabled,
    &:disabled + .suffix {
      color: #000;
    }
  }

  td.fremdkosten {
    .prefix {
      position: absolute;
      padding: 0.25em;
    }

    input[type="text"][readonly] {
      cursor: pointer;
    }
  }

  span.costs {
    display: inline-block;
    text-align: right;
    width: 64px;
    color: dodgerblue;
    font-weight: bold;
  }
}

.pre {
  white-space: pre-wrap;
}

th .pie {
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  border: none;
  box-shadow: none;
}
</style>