<template>
  <v-dialog v-model="dialog" scrollable max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
            
            color="primary" v-bind="attrs" v-on="on"><v-icon small>mdi-account-group-outline</v-icon></v-btn>
    </template>
   <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
       
        Zugriff auf die Budgetübersicht "{{ division.name }}"
        
      </v-card-title>
      <v-card-text>
          <DivisionUsers :division="division" />
      </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog=false">
          {{ trl('close') }}
        </v-btn>
      </v-card-actions>
   </v-card>
  </v-dialog>
</template>

<script>
import { trl } from '@/utils/strings';
import DivisionUsers from '@/components/DivisionUsers';
export default {
    name:"DivisionUsersModal",
    props:["division"],
    data(){
        return{
            dialog: false
        }
    },
    components:{DivisionUsers},
    methods:{trl}
}
</script>

<style>

</style>