<template>
  <v-menu
    :value="showing"
    offset-y
    rounded="lg"
    close-on-click
    close-on-content-click
    transition="scale-transition"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="{ ...menu }"
        @click="showing = true"
        >{{ project.rowIcon }}</v-icon
      >
    </template>
    <v-list dense>

      <!-- Bearbeiten --> 

      <ProjectForm :projectdata="project" @accept="editDone">
        <v-list-item @click="menuClick">
          <v-icon size="20" class="mr-4">mdi-square-edit-outline</v-icon>
          <v-list-item-title>Bearbeiten</v-list-item-title>
        </v-list-item>
      </ProjectForm>

      <!-- Duplizieren --> 

      <v-list-item @click="onClick({action: 'duplicate'})">
        <v-icon size="20" class="mr-4">mdi-content-duplicate</v-icon>
         <v-list-item-title>Zeile duplizieren</v-list-item-title>
      </v-list-item>

      <!-- Löschen, bzw. wiederherstellen --> 

      <v-list-item @click="onClick({action: 'delete'})">
        <v-icon size="20" class="mr-4">{{ project.deleted_at ? "mdi-delete-restore" : "mdi-delete-outline" }}</v-icon>
         <v-list-item-title>{{ project.deleted_at ? "Zeile behalten" : "Zeile löschen" }}</v-list-item-title>
      </v-list-item>

      <!-- History --> 

      <History :project="project" v-if="Array.from(project.id)[0] !== '_'">
      <v-list-item @click="menuClick">
                <v-icon size="20" class="mr-4">mdi-timeline-text-outline</v-icon>
              <v-list-item-title>Timeline</v-list-item-title>
            </v-list-item>
      </History>
       <v-list-item v-else @click="notAvailable" style="opacity:0.5">
                <v-icon size="20" class="mr-4">mdi-timeline-text-outline</v-icon>
              <v-list-item-title>Timeline</v-list-item-title>
            </v-list-item>

      <!-- Transferieren --> 

      <Transfer :project="project" @accept="transferDone" v-if="Array.from(project.id)[0] !== '_'">
      <v-list-item @click="menuClick">
                <v-icon size="20" class="mr-4">mdi-transfer-right</v-icon>
              <v-list-item-title>Transferieren</v-list-item-title>
            </v-list-item>
      </Transfer>
      <v-list-item v-else @click="notAvailable" style="opacity:0.5">
         <v-icon size="20" class="mr-4">mdi-transfer-right</v-icon>
          <v-list-item-title>Transferieren</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ProjectForm from "@/components/forms/Project";
import History from "@/components/forms/History";
import Transfer from "@/components/forms/Transfer";

export default {
  name: "BudgetContextMenu",
  props: ["project"],
  data: () => ({
    showing: false,
  }),
  methods: {
    editDone(updatedProject) {
      this.$emit("click", {action: "edit", project: updatedProject});
    },
    transferDone(data) {
      this.$emit("click", {action: "transfer", data: data});
    },
    onClick(item) {
      let data = {
        action: item.action,
        project: this.project,
      };
      this.$emit("click", data);
    },
    menuClick(){
      this.showing = false;
    },
    myDelete() {
      this.project.deleted_at = true;
    },
    notAvailable(){
      this.$store.commit("alert", {
          title: "Hinweis",
          text: "Diese Funktion steht für neu angelegte und noch nicht gespeicherte Elemente nicht zur Verfügung.",
        });
    }
  },
  components: { ProjectForm, Transfer, History },
};
</script>

<style>
</style>