<template>
  <v-dialog v-model="dialog" max-width="640px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on"
        ><v-icon small>mdi-tray-arrow-down</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        Download
      </v-card-title>
      <v-card-text>
        <p>
          <strong
            >Lade deine Zahlen im CSV-Format für Microsoft Excel&trade;
            herunter.</strong
          >
        </p>
        <v-radio-group v-model="report">
          <v-radio value="budget" label="Budgetzeilen (Kampagnen)"></v-radio>
          <v-row style="margin-bottom:-4px">
          <v-col cols="6">
            <v-radio value="accumulated" label="Budget kumuliert..."></v-radio>
          </v-col>
          <v-col cols="6">
           <v-select 
            style="margin-top:-4px"
            v-show="report === 'accumulated'"
            v-model="groupby"
            label="auf"
            outlined
            dense
            hide-details
            multiple
            :items="groups"
        ></v-select>
          </v-col>
</v-row>
          <v-radio value="costs" label="Fremdkosten"></v-radio>
        </v-radio-group>
       
       <v-divider></v-divider>
       <v-select
        class="mt-6"
        v-model="fields"
        label="enthaltene Budgetspalten"
        :disabled="report==='costs'"
        :items="$store.state.budget.fields.filter(f => f.sumup).map((f) =>  {
          return {key: f.key, label: trl('budget_' + f.key)}
          })"
        item-value="key"
        item-text="label"
        outlined
        prepend-icon="mdi-format-columns"
        multiple
       >
              
       
       </v-select>

        <v-select
         
          label="Snapshot wählen"
          v-model="snapshot"
          :items="snapshots"
          item-value="id"
          item-text="title"
          outlined
          prepend-icon="mdi-calendar-star"
          @change="fn"
        ></v-select>

          <v-text-field
            v-model="filename"
            prepend-icon="mdi-file-download-outline"
            label="Speichern unter"
            outlined
           
          ></v-text-field></v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">
          {{ trl("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="download">
          {{ trl("download") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { trl } from "@/utils/strings";
import { DateTime } from "luxon";

export default {
  name: "Download",
  props: ["query","defaults"],
  data() {
    return {
      dialog: false,
      forecast: null,
      report: "budget",
      groupby: ["client_id"],
      filename: "",
      snapshot: null,
      fields: ["total", "fremdkosten", "total_wo_fk"],
      groups: [
        {text: 'Kunde', value: 'client_id'},
        {text: 'Unit', value: 'division_id'},
        {text: 'Gattung', value: 'attribute_id'},
        {text: 'Status', value: 'status'},
        ]
    };
  },
  computed: {
    ...mapGetters(["forecasts"]),
    snapshots(){
        let snaps = [];
        let fc =  this.forecasts.find(item => item.id === this.query.fcid);

        if (fc) {
          snaps = fc.snapshots.filter((s) => s.closed_at)
          if (fc.active) snaps.push({ id: null, title: 'live' })
        }

        return snaps;

    }
  },
  methods: {
      fn() {

      let fn = [];
      fn.push(this.forecast.title);  
      
      let reportName = this.report === "costs" ? "Fremdkosten" : "Budgetzahlen";
      fn.push(reportName);

      let tenantName = this.$store.getters.current.name;

      fn.push(tenantName);

      if (typeof this.query.division === 'number') {
        let divisionName = this.$store.getters.divisions.find(item => item.id === this.query.division).name;
        if (divisionName !== tenantName) {
          fn.push(divisionName);
        }
      }

      if (this.snapshot) {
        fn.push("Snapshot " + this.forecast.snapshots.filter(item => item.id === this.snapshot)[0].title);
      } else {
        if (this.query.until) {
          fn.push(DateTime.fromSQL(this.query.until).toISODate())
        } else {
          fn.push(DateTime.now().toISODate());
        }
      }

      this.filename = fn.join(" ");
    },
    async download() {

      let params = JSON.parse(JSON.stringify(this.query));

      params.filename = this.filename;
      params.download = this.report;
      params.fields = this.fields;

      if (this.report === "accumulated") {
        params.groupby = this.groupby;
      }

      delete params.snapshot;
      if (this.snapshot) {
        params.snapshot = this.snapshot;
      }

      await this.$store.dispatch("dwnld", params);
      this.dialog = false;
    },
    trl,
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.filename = ""
        return;
      }

      this.forecast = this.$store.getters.forecasts.find(f => f.id === this.query.fcid);
      this.snapshot = this.query.snapshot || null;
      this.fn();
      this.report = this.defaults?.report || "budget";

      if (this.query.division) {
        // "Kumuliert nach Unit" ist keine Option, wenn der Dialog nur für eine (1) Unit aufgerufen wird.
        let i = this.groups.findIndex(g => g.value === "division_id");
        this.groups.splice(i, 1);
      }

    },
    report: function() {
     this.fn();
    }
  }
};
</script>

<style>
</style>