<template>
  <v-dialog v-model="dialog" width="640">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        Fremdkosten "{{ proj.budget.title }}"
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="my-12">
      <v-row v-for="(item, index) in costs" :key="index" >
        <v-col cols="1">
          <v-icon dense @click="deleteRow(index)">mdi-delete-outline</v-icon>
        </v-col>
        <v-col cols="2">
          <v-text-field dense hide-details v-model="item.value" @blur="numberHandler(index)" label="T€" class="right"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select 
          dense 
          hide-details 
          label="Rechnungssteller" 
          v-model="item.vendor_id" 
          :items="vendors" 
          item-text="name" 
          item-value="id"
          :rules="[(v) => (typeof v === 'number' && v > -1) || 'Bitte auswählen']"
        ></v-select>
        </v-col>
        <v-col cols="5">
          <v-text-field dense hide-details label="Anmerkung" v-model.trim="item.comment"></v-text-field>
        </v-col>
        <v-divider />
      </v-row>
      <v-row>
        <v-col>
      <v-icon dense @click="addRow">mdi-plus-circle-outline</v-icon>

        </v-col>
      </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text color="primary">{{
          trl("cancel")
        }}</v-btn>
        <v-btn @click="accept" :disabled="!valid" color="primary">{{ trl("accept") }}</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import { trl } from "@/utils/strings";
import { fromLocale, toLocale } from "@/utils/numbers";
export default {
  props: ["proj"],
  data() {
    return {
      dialog: false,
      valid: true,
      costs: [],
      last: [],
      template: { value: toLocale(0), comment: null, vendor_id: null },
      vendors: []
    };
  },
  methods: {
    trl,
    addRow() {
      this.costs.push(JSON.parse(JSON.stringify(this.template)));
      this.last.push(JSON.parse(JSON.stringify(this.template)));
    },
    deleteRow(index){
        this.costs.splice(index, 1);
    },
    accept() {

        if (!this.$refs.form.validate()) {
          return;
        }

        this.proj.budget.costs = this.costs.filter(c => fromLocale(c.value) > 0);
        this.proj.budget.fremdkosten = toLocale(this.costs.reduce((accumulator, current) => accumulator + fromLocale(current.value), 0), 2);
        this.$emit('accept');
        this.dialog = false;
    },
    numberHandler(index) {
      const current = this.costs[index];
      const last = this.last[index];
      current.value = toLocale(fromLocale(current.value), 2);
      last.value = JSON.parse(JSON.stringify(current.value));
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.proj.rowIcon = "mdi-dots-horizontal";
        this.costs = [];
        return;
      }


      this.proj.rowIcon = "mdi-dock-window";
      this.costs = JSON.parse(JSON.stringify(this.proj.budget.costs)) || [];
     
      if (this.costs.length === 0) {
        this.costs = [JSON.parse(JSON.stringify(this.template))]
      }

      this.last = JSON.parse(JSON.stringify(this.costs));
      this.saved = JSON.parse(JSON.stringify(this.costs));

      this.$store.getters.vendors.forEach(v => {
        if (v.vis) {
          this.vendors.push({
            id: v.id,
            name: v.name,
            disabled: false
          })
          return;
        }

        if (this.costs.map(c => c.vendor_id).indexOf(v.id) > -1) {
          this.vendors.push({
            id: v.id,
            name: v.name,
            disabled: true
          })
        }

      })
      this.vendors.push({id: 0, name:"Neu (s. Anmerkung)"})


      //this.vendors = this.$store.getters.vendors.filter(v => v.vis || this.costs.map(c => c.vendor_id).indexOf(v.id) > -1)


    },
  },
};
</script>

<style lang="scss">
.v-text-field.right input{
  text-align:right !important;
}
</style>