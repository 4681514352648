<template>
  <v-dialog v-model="dialog"  persistent @keydown.esc="dialog=false" width="940" height="500">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        {{ title }}
      </v-card-title>
      <v-form ref="form" class="ma-12" @submit.prevent="accept">
        <v-autocomplete
          v-model="project.budget.client_id"
          @change="analyzeClient()"
          :ripple="false"
          :rules="clientRules"
          item-text="name"
          item-value="id"
          :items="projectClients"
          outlined
          label="Kunde"
          :hint="getDivisionsForClient(project.budget.client_id)"
          persistent-hint
          class="mb-2"
        ></v-autocomplete>

        <v-text-field
          v-model.trim="project.budget.title"
          outlined
          label="Kampagnenbezeichnung"
           :rules="titleRules"
        ></v-text-field>
  
        <v-row class="mt-n4">
        
          <v-col cols="8">

        <v-radio-group row class="mt-n1" label="Honorarbasis" v-model="project.budget.honorar_basis_field">
            <v-radio
              v-for="item in ['kundennetto','agenturnetto']"
              :key="item"
              :label="trl('budget_' + item)"
              :value="item"
              dense
            ></v-radio>
          </v-radio-group>
          </v-col>
        </v-row>
        <div v-if="!project.id">
          <!-- neues Projekt anlegen -->
          <p class="small">
            Wähle <strong>mindestens eine Gattung</strong> für diese Kampagne. Markierst du mehr als eine
            Checkbox, wird die Kampagne für jede gewählte Gattung angelegt
            (eigene Zeile), und du verwaltest Budget und Income für jede Gattung
            separat.
          </p>
          <v-layout col wrap cols="3" class="px-0">
            <v-checkbox
              style="width: 33%"
              v-for="item in attributes.filter(x => x.active)"
              :key="item.id"
              v-model="project.budget.attributes"
              :value="item.id"
              :label="item.value"
              dense
              color="primary"
            ></v-checkbox>
          </v-layout>
          <p class="small mt-3">
            Wähle <strong>mindestens einen Budgetstatus</strong> für diese Kampagne. Markierst du mehr als eine
            Checkbox, wird die Kampagne für jede Gattung und jeden Status
            angelegt, und du kannst Budget und Income im Laufes des Jahres
            zwischen den einzelnen Status verschieben.
          </p>
          <v-layout>
            <v-row
              ><v-col>
                <v-checkbox
                  v-for="n in $store.state.budget.status"
                  :key="n.value"
                  :label="n.label"
                  :value="n.value"
                  :color="n.color"
                  v-model="project.budget.status"
                  :disabled="n.value !== 0 && project.budget.client_id === 0"
                  dense
                  class="d-inline-flex mr-6"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-layout>
         
        </div>
        <div v-else>
         <!-- bestehendes Projekt aktualisieren --> 
          <label>Gattung</label>
          <v-radio-group  row class="px-0" v-model="project.budget.attribute_id">
            <v-radio
              style="width:31%;margin-bottom:4px"
              v-for="item in attributes.filter(x => x.active || x.id == project.budget.attribute_id)"
              :key="item.id"
              :label="item.value"
              :value="item.id"
              dense
            ></v-radio>
          </v-radio-group>
         <label>Budgetstatus</label>
          <v-radio-group  row v-model="project.budget.status">
            <v-radio
              v-for="n in $store.state.budget.status"
              :key="n.value"
              :label="n.label"
              :value="n.value"
              :color="n.color"
              :disabled="n.value !== 0 && project.budget.client_id === 0"
              dense
              class="mr-6"
            ></v-radio>
          </v-radio-group>
        </div>
       </v-form>
      <v-divider class="pt-6 mx-12"></v-divider>
      <v-card-actions>
        <div class="ml-8" v-html="projectsCount"></div>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text color="primary">{{
          trl("cancel")
        }}</v-btn>
        <v-btn @click="accept" color="primary">{{ trl("accept") }}</v-btn>
      </v-card-actions>
     
    </v-card>
  </v-dialog>
</template>

<script>
import { trl, numerus, smartjoin, getDivisionName } from "@/utils/strings";
import { toLocale } from "@/utils/numbers";

import { mapGetters } from "vuex";
export default {
  props: ["projectdata"],
  name: "ProjectForm",
  data() {
    return {
      dialog: false,
      title: "",
      project: {
        name: null,
        forecast_id: null,
        division_id: null,
        budget: {
          status: [],
          attributes: [],
        },
      },
      clientRules: [
        v => (typeof v === 'number' && v > -1) || 'Bitte wähle einen Kunden aus.', 
      ],
      titleRules: [
        v => !!v || 'Bitte vergebe einen Titel für die Kampagne.', 
      ]
    };
  },
  methods: {
   accept(){

      const isFormValid = this.$refs.form.validate();

      // console.log(isFormValid);

      // if (this.project.client_id === "" || this.project.name === null) {
      //   return;
      // }

      if (!isFormValid) return;

      if (!this.project.id) {
        let data = [];

        this.project.budget.status.sort();

        let groupId = (Math.random() * Date.now()).toString();

        for (let i = 0; i < this.project.budget.attributes.length; i++) {
            for (let j = 0; j < this.project.budget.status.length; j++) {

              data.push({
                id: '_' + (Math.random() * Date.now()), // temporäre ID
                group_id: groupId,
                rowIcon: "mdi-dots-horizontal",
                forecast_id: this.project.forecast_id,
                division_id: this.project.division_id,
                deleted_at: null,
                budget:{
                  title: this.project.budget.title,
                  forecast_id: this.project.forecast_id,
                  attribute_id: this.project.budget.attributes[i],
                  status: this.project.budget.status[j],
                  client_id: this.project.budget.client_id,
                  honorar_basis_field: this.project.budget.honorar_basis_field,
                  kundennetto: toLocale(0),
                  ae: toLocale(0),
                  agenturnetto: toLocale(0),
                  honorar_basis_percent: toLocale(0),
                  honorar_basis: toLocale(0),
                  honorar_sokos: toLocale(0),
                  income_other: toLocale(0),
                  total: toLocale(0),
                  fremdkosten: toLocale(0),
                  total_wo_fk: toLocale(0),
                  comment: "",
                  costs: null,
                }
              })
            }
        }

        if (data.length === 0) {
          return;
        }

        this.$emit('accept', data); // <-- geht an @/components/Budget.vue
        this.dialog = false;
      } else {
        this.project.rowIcon = "mdi-dots-horizontal",
        this.$emit('accept', this.project); // <-- geht an @/components/BudgetContextMenu.vue
        this.dialog = false;
      }
   },
   analyzeClient(){
     if (this.project.budget.client_id === 0) {
       this.project.budget.status = this.project.id ? 0 : [0];
     }
   },
   getDivisionsForClient(client_id){

       if (!client_id || this.userDivisions.length < 2) return;

        let client = this.clientsWithProjects.find(c => c.id === client_id);
        if (!client || client.divisions.length === 0) {
          return "Keine Unit führt bislang Budget dieses Kunden.";
        }

        let divisions = [...client.divisions];
        
        if (divisions.length === 1 && divisions[0] === this.project.division_id) {
          return "Deine Unit führt als einzige Budget dieses Kunden.";
        }

        let infoString = "bereits";
        let index = divisions.indexOf(this.project.division_id);
        if (index > -1) {
          infoString = "ebenfalls";
          divisions.splice(index, 1);
        }

        let str = numerus("Die Unit|s xxx führ|t|en " + infoString + " Budget dieses Kunden.", divisions.length);
        str = str.replace("xxx", smartjoin(divisions.map(cd => '"' + this.getDivisionName(cd) + '"'), "und"));
        return str;
   },
    trl,
    getDivisionName
  },
  computed: {
    ...mapGetters(["current", "userClients", "clientsWithProjects","divisions", "userDivisions", "forecasts", "attributes"]),
    projectClients(){
      return this.$store.getters.userClients(this.project.division_id).filter(item => item.active || this.project.budget.client_id === item.id)
    },
    projectsCount() {

      if (this.project.id) return;

      const budget = this.project.budget;
      const attributes =
        typeof budget.attributes === "object" ? budget.attributes.length : 1;
      const status =
        typeof budget.status === "object" ? budget.status.length : 1;
      let ctrProjects = attributes * status;

      if (ctrProjects === 0) {
        return '<i class="mdi mdi-alert"></i><span>Wähle mindestens 1 Gattung und 1 Budgetstatus.</span>';
      } else {

        let iconNumber = ctrProjects < 10 ?  ctrProjects.toString() : "9-plus";

        return '<i class="mdi mdi-numeric-' + iconNumber + '-circle"></i><span>' + numerus("Es wird||werden ? Kampagnenzeile|n angelegt.", ctrProjects) + '</span>';
      }
    }
  },
  watch: {
    dialog: function (val) {

      if (!val) {
        this.projectdata.rowIcon = "mdi-dots-horizontal";
        return;
      }

      if (this.projectdata.id) {
        this.projectdata.rowIcon = "mdi-dock-window";
        this.project = JSON.parse(JSON.stringify(this.projectdata));
      } else {
       
        this.project = {
          rowIcon: "mdi-dock-window",
          forecast_id: this.projectdata.forecast_id,
          division_id: this.projectdata.division_id,
          budget: {
            title: null,
            client_id: this.projectdata.budget?.client_id,
            honorar_basis_field: "agenturnetto",
            status:[],
            attributes: []
          }
        }

        if (this.project.budget.client_id === 0) {
          this.project.budget.status=[0];
        }

      }

      let str_forecast = this.forecasts.find(item => item.id === this.project.forecast_id).title;
      let str_division = this.getDivisionName(this.project.division_id);
      let str_action = this.project.id ? "Kampagne bearbeiten" : "Kampagne anlegen";

      this.title = str_forecast + " - " + str_division + " - " + str_action

      this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

::v-deep .v-input--dense > .v-input__control {
  height: 1.25rem;
}

label{
  font-size:14px;
}


</style>