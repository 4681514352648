<template>
  <v-dialog v-model="dialog" width="1024" height="800">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card v-if="selected.source.fcid && selected.source.division && projectsInForecast.length">
      <v-card-title class="text-h6 primary white--text mb-6">
        <i class="mdi mdi-database-export-outline"></i><span>{{ headline }}</span>
      </v-card-title>
     
      <p class="mx-6 mb-6">
        Wähle in der linken Spalte die Kunden aus, die du in den neuen Forecast
        übernehmen möchtest.<br>Wenn du auf einen Kundennamen klickst,werden in
        der rechten Spalte die Kampagnen dieses Kunden im {{ this.forecasts.find(f => f.id === this.source.fcid).title }}
        angezeigt.<br>Falls du diese auch übernehmen möchtest, wähle sie aus (erste Checkbox) oder klicke direkt die zweite Checkbox in der Kampagnenzeile, um das Budget ebenfalls zu übernehmen.
      </p>
      
      <p class="mx-6">
        <v-select 
          label="Wie möchtest du beginnen?"
          v-model="selected.importMethod"
          :items="importMethods"
          item-text="title"
          item-value="id"
          @change="selectImportMethod"
          outlined
        >
        </v-select>
      </p>

      <v-row class="mx-6" style="height:520px">
        <v-col cols="5" style="height:500px;overflow-x: hidden;overflow-y:auto;background-color:rgba(0,0,0,0.1);border-radius:5px;padding:0px">
         
          <li 
            v-for="n in clientsInForecast"
            :key="n.id"
              class="p-import" 
            :class="{ current: clicked === n.id }"
          >
          <v-checkbox
            :value="n.id"
            v-model="selected.clients"
            dense
            hide-details
            :disabled="query.clients.includes(n.id)"
            @click="selectClient(n.id)"
          >
          </v-checkbox>
            <div class="clickable" @click.prevent="clickClient(n.id, $event)"> 
              <strong>{{
                n.name
              }}</strong>
            </div>
          </li>
        </v-col>
        

        <v-col cols="7" style="height:500px;overflow-y:auto">
          <img v-if="clientProjects.length" src="/assets/importWizard-legend.png" alt="">
          <li 
            class="p-import" 
            v-for="n in clientProjects"
            :key="n.id"
          >
          <v-checkbox
            :value="n.id"
            v-model="selected.projects"
            dense
            hide-details
            title="Kampagne übernehmen"
            @click="includeProject(n.id)"
            :disabled="query.projects.includes(n.id)"
          ></v-checkbox>

          <v-checkbox
            :value="n.id"
            v-model="selected.budgets"
            dense
            hide-details
            title="Budgetzahlen inkludieren"
            @click="includeBudget(n.id)"
            :disabled="query.projects.includes(n.id)"
          ></v-checkbox>

          <span>
            <strong v-html="titleAdjustedForFiscalYear(n.budget.title, true)"></strong>
            &nbsp;&ndash;&nbsp;
            {{ getAttributeName(n.budget.attribute_id) }}
            &nbsp;&ndash;&nbsp;
            {{ n.budget.total_wo_fk }}
            <span v-html="
                  $store.state.budget.fields.find(
                    (f) => f.key === 'total_wo_fk'
                  ).suffix
                "
              ></span>
          </span>
          </li>
        </v-col>
      </v-row>
      <v-banner v-if="clientsInForecast.map(c => c.id).some(e => query.clients.includes(e) )" class="warning">
        <small>In deinem Forecast befinden sich bereits aus “{{ this.forecasts.find(f => f.id === this.source.fcid).title }}” übernommene Daten. Diese sind hier ausgegraut markiert und werden nicht erneut übernommen.<br />
Um sie aus dem neuen Forecast zu löschen oder <em><strong>"anders"</strong></em> zu übernehmen (z.B. <em><u>mit</u></em> Budget statt <em><u>ohne</u></em> Budget), lösche sie zuerst in deiner Forecast-Übersicht im Hauptfenster und öffne den Daten-Übernahme-Assistenen erneut.</small>
      </v-banner>
      <v-card-actions>
        <v-btn 
        @click="reset"
        depressed
        color="grey lighten-2">{{ trl("back") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text>{{ trl("cancel") }}</v-btn>
        <v-btn @click="accept" color="primary">{{ trl("accept") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="text-h6 primary white--text mb-6">
        <i class="mdi mdi-database-export"></i><span>{{ headline }}</span>
      </v-card-title>
      <!-- <h2 class="mx-6 mb-4">Hast du 5 Minuten Zeit?</h2> -->
      <p class="mx-6">
        Der Datenübernahme-Assistent unterstützt dich, aus einem bestehenden Forecast <strong>Kunden, Kampagnen und Budgetzahlen</strong> in einen neuen Forecast zu übernehmen.
      </p>
      <p class="mx-6">
        Du kannst für jeden Kunden aus dem bestehenden Forecast entscheiden, ob du
        <ul class="my-2">
          <li>nur den Kunden übernimmst (und dann neue Kampagnen für den Forecast erstellst)</li>
          <li>alle oder ausgewählte Kampagnen des Kunden in den neuen Forecast überträgst</li>
          <li>wahlweise die Kampagnen-Budgets des alten Forecasts übernimmst.</li>
        </ul>
        Beim Übernehmen bestehender Kampagnen wird der Status "freigegeben" wieder auf "Planbudget" geändert.<br /> Alle übernommenen Daten lassen sich natürlich noch beliebig anpassen, ehe du den neuen Forecast das erste Mal speicherst.
      </p>
    
      <p class="mx-6">
        <strong>Wähle nun den Forecast, aus dem du Daten übernehmen möchtest:</strong>
        <p class="mx-6">
          <v-select
            v-model="source.fcid"
            :items="forecasts.filter((f) => f.vis && f.id !== query.fcid)"
            item-text="title"
            item-value="id"
            label="Forecast wählen"
            outlined
          >
          </v-select>
          <v-select
            v-if="source.fcid && availableDivisions.length > 0"
            v-model="source.division"
            :items="availableDivisions"
            item-text="name"
            item-value="id"
            label="Aus welcher Unit sollen die Daten übernommen werden?"
            outlined
          >
          </v-select>
        </p>
        <v-banner color="warning" v-if="selected.source.fcid && selected.source.division && projectsInForecast.length === 0">Keine Daten zur Übernahme gefunden.</v-banner>
    
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text>{{ trl("cancel") }}</v-btn>
        <v-btn color="primary" @click="setSource"
          >Weiter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  trl,
  getDivisionName,
  getAttributeName,
  getStatus,
} from "@/utils/strings";
import { toLocale } from "@/utils/numbers";
import { mapGetters } from "vuex";
export default {
  props: ["query"],
  data() {
    return {
      dialog: false,
      source: {
        fcid: null,
        division: this.query.division
      },
      importMethods: [
        {id: 1, title: "Ich möchte Kunden und Kampagnen selbst auswählen"},
        {id: 2, title: "Ich möchte alle Kunden ohne Kampagnen auswählen"},
        {id: 3, title: "Ich möchte alle Kunden und alle Kampagnen auswählen"},
        {id: 4, title: "Ich möchte alle Kunden und alle Kampagnen inkl. Budgetzahlen auswählen"},
      ],
      fiscalYears: {
        source: "",
        target: ""
      },
      clientsInForecast: [],
      projectsInForecast: [],
      clicked: null,
      clientProjects: [],
      selected: {
        importMethod: 1,
        source: {
          fcid: null,
          division: null
        },
        clients: [],
        projects: [],
        budgets: [],
      },
    };
  },
  computed: {
    ...mapGetters(["clients", "forecasts"]),
    headline(){
      let fc = this.forecasts.find(f => f.id === this.source.fcid);
      return "Daten-Übernahme aus " + (fc?.title || "einem bestehenden Forecast") + " der Unit " + this.getDivisionName(this.source.division);
    },
    availableDivisions(){
      if (!this.source.fcid) return [];
      let fy = this.forecasts.find(f => f.id === this.source.fcid).fy;
      return this.$store.getters.ud2(fy);
    }
  },
  methods: {
    trl,
    getDivisionName,
    getAttributeName,
    getStatus,
    reset() {
      this.selected = {
        importMethod: 1,
        source: {
          fcid: null,
          division: null
        },
        clients: [...new Set(this.query.clients)],
        projects: [...this.query.projects],
        budgets: [...this.query.budgets]
      }
    },
    async setSource(){
      this.selected.source = {
        fcid: this.source.fcid,
        division: this.source.division
      }
      this.fiscalYears = {
        source: this.forecasts.find(f => f.id === this.selected.source.fcid).fy,
        target: this.forecasts.find(f => f.id === this.query.fcid).fy
      }

      await this.fetchBudget();

      

      this.clickClient(this.clientsInForecast[0].id);
      
    },
    selectImportMethod(){
      switch (this.selected.importMethod) {
        case 1: // aKunden und Kampagnen selbst auswählen
          this.selected.clients = [...new Set(this.query.clients)];
          this.selected.projects = [...this.query.projects];
          this.selected.budgets = [...this.query.budgets];
          break;
        case 2: // alle Kunden ohne Kampagnen auswählen
          this.selected.clients = this.clientsInForecast.map(x => x.id);
          this.selected.projects = [...this.query.projects];;
          this.selected.budgets = [...this.query.budgets];
          break;
        case 3: // Kunden mit Kampagnen (ohne Budgets)
          this.selected.clients = this.clientsInForecast.map(x => x.id);
          this.selected.projects = this.projectsInForecast.map(x => x.id);
          this.selected.budgets = [...this.query.budgets];
          break;
        case 4: // Kunden mit Kampagnen und Budgets
          this.selected.clients = this.clientsInForecast.map(x => x.id);
          this.selected.projects = this.projectsInForecast.map(x => x.id);
          this.selected.budgets = this.projectsInForecast.map(x => x.id);
          break;
      }
    },
    clickClient(id, evt) {
      if (evt) evt.stopImmediatePropagation();
      this.clientProjects = this.projectsInForecast.filter(
        (p) => p.budget.client_id === id
      );
      this.clicked = id;
    },
    selectClient(id) {
      this.clicked = id;

      let index = this.selected.clients.indexOf(id);

      this.clientProjects = this.projectsInForecast.filter(
        (p) => p.budget.client_id === id
      );

      if (index === -1) {
        
        this.selected.projects = this.selected.projects.filter(
          (x) => !this.clientProjects.map((p) => p.id).includes(x)
        );

        this.selected.budgets = this.selected.budgets.filter(
          (x) => !this.clientProjects.map((p) => p.id).includes(x)
        );
      }
    },
    includeBudget(id) {
      if (this.selected.budgets.indexOf(id) > -1) this.selected.projects.push(id);
      this.includeClient(id);
    },
    includeProject(id){

      if(!this.selected.projects.includes(id)) {
        let indexBudgets = this.selected.budgets.indexOf(id);
        if (indexBudgets > -1) {
          this.selected.budgets.splice(indexBudgets, 1);
        }
      }

      this.includeClient(id);
    },
    includeClient(projectId){
        let cid = this.projectsInForecast.find((p) => p.id === projectId).budget.client_id
        if (this.selected.clients.indexOf(cid) === -1) {
            this.selected.clients.push(cid);
        }
    },
    async fetchBudget() {

      let params = {
        fcid: this.selected.source.fcid,
        division: this.selected.source.division,
      };

      const projects = await this.$store.dispatch("req", {
        route:
          "budget?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });

      projects.forEach((p) => {
        p.deleted_at = null;
        this.$store.state.budget.fields.forEach(
          (f) => (p.budget[f.key] = toLocale(p.budget[f.key], f.round))
        );

        if (p.budget.costs) {
          p.budget.costs.forEach((c) => (c.value = toLocale(c.value, 2)));
        }
      });

      this.projectsInForecast = JSON.parse(JSON.stringify(projects));

      let clientIds = [...new Set(projects.map((p) => p.budget.client_id))];

      this.clientsInForecast = [];
      clientIds.forEach((id) =>
        this.clientsInForecast.push(this.clients.find((c) => c.id === id))
      );

      let hasDisabled = false;
      this.clientsInForecast.forEach(cid => {
        hasDisabled = this.query.clients.includes(cid);
      })

      this.clientsInForecast.sort((a, b) => {
        let a_name = a.name.toLowerCase();
        let b_name = b.name.toLowerCase();
        return a_name == b_name ? 0 : b_name > a_name ? -1 : 1;
      });
    },
    accept() {
      let data = [];
      this.selected.projects
        .filter(pid => !this.query.projects.includes(pid)) // nur neu ausgewählte berücksichtigen; keine schon vorhandenen
        .forEach((pid) => {
          let p =  JSON.parse(JSON.stringify(this.projectsInForecast.find(obj => obj.id === pid)));

          p.id = "_import-" + Math.random() * Date.now();  // temporäre ID,
          p.parent_id = pid;
          p.parent = {
            id: pid,
            division: this.selected.source.division,
            forecast: this.selected.source.fcid,
            project: false
          }

          p.division_id = this.query.division;
          p.rowIcon = "mdi-dots-horizontal",
          p.forecast_id = this.query.fcid;
          p.budget.forecast_id = this.query.fcid;
          p.budget.initials = null;
          p.budget.title = this.titleAdjustedForFiscalYear(p.budget.title, false);
          p.budget.status = p.budget.status === 2 ? 1 : p.budget.status; // Status gruen auf gelb setzen

          // keine Werte uebernehmen
          if (this.selected.budgets.indexOf(pid) === -1) {
            p.budget.kundennetto = toLocale(0);
            p.budget.ae = toLocale(0);
            p.budget.agenturnetto = toLocale(0);
            p.budget.honorar_basis_percent = toLocale(0);
            p.budget.honorar_basis = toLocale(0);
            p.budget.honorar_sokos = toLocale(0);
            p.budget.income_other = toLocale(0);
            p.budget.total = toLocale(0);
            p.budget.fremdkosten = toLocale(0);
            p.budget.total_wo_fk = toLocale(0);
            p.budget.comment = "";
            p.budget.costs = null;
          } else {
            p.importWithBudget = true;
          }

          data.push(p);

        })

      let clientsWithProjects = [...new Set(data.map(obj => obj.budget.client_id))];

      this.selected.clients
        .filter(cid => !this.query.clients.includes(cid) && !clientsWithProjects.includes(cid))
        .forEach((cid) => {

          data.push({
            id: "_import-" + Math.random() * Date.now(), // temporäre ID,
            rowIcon: "mdi-dots-horizontal",
            forecast_id: this.query.fcid,
            division_id: this.query.division,
            parent_id: 0,
            parent: {
              division: this.selected.source.division,
              forecast: this.selected.source.fcid,
              project: false
            },
            budget: {
              forecast_id: this.query.fcid,
              client_id: cid,
              status: 0,
              title: "EMPTY - JUST SHOW CLIENT",
              kundennetto: toLocale(0),
              ae: toLocale(0),
              agenturnetto: toLocale(0),
              honorar_basis_percent: toLocale(0),
              honorar_basis: toLocale(0),
              honorar_sokos: toLocale(0),
              income_other: toLocale(0),
              total: toLocale(0),
              fremdkosten: toLocale(0),
              total_wo_fk: toLocale(0),
              comment: "",
              costs: null,
            },
          });
        })


      this.$store.dispatch("req", {
          method: "GET",
          route:  'log?importMethod=' + this.selected.importMethod + '&fcid=' + this.selected.source.fcid + 
                  '&numClients=' + this.selected.clients.length + '/' + this.clientsInForecast.length + 
                  '&numProjects=' + this.selected.projects.length + '/' + this.projectsInForecast.length + 
                  '&numBudget=' + this.selected.budgets.length + '/' + this.projectsInForecast.length
        });

      this.$emit("accept", data); // <-- geht an @/components/Budget.vue
      this.dialog = false;
    },
    titleAdjustedForFiscalYear(str, preview) {

      let yearSource = this.fiscalYears.source.toString();
      let yearTarget = this.fiscalYears.target.toString();
      let yearBefore = (eval(yearSource) - 1).toString();
      let yearAfter = (eval(yearTarget) + 1).toString();

      str = (str || '').toString();

      if (preview) {

          str = str.split(yearTarget).join(`<del>${yearTarget}</del> ${yearAfter}`);
          str = str.split(` ${yearTarget.substr(2)}`).join(`<del> ${yearTarget.substr(2)}</del> ${yearAfter.substr(2)}`);
          str = str.split(`/${yearTarget.substr(2)}`).join(`<del>/${yearTarget.substr(2)}</del> /${yearAfter.substr(2)}`);

          str = str.split(yearSource).join(`<del>${yearSource}</del> ${yearTarget}`);
          str = str.split(` ${yearSource.substr(2)}`).join(`<del> ${yearSource.substr(2)}</del> ${yearTarget.substr(2)}`);
          str = str.split(`/${yearSource.substr(2)}`).join(`<del>/${yearSource.substr(2)}</del> /${yearTarget.substr(2)}`);

          str = str.split(yearBefore).join(`<del>${yearBefore}</del> ${yearSource}`);
          str = str.split(` ${yearBefore.substr(2)}`).join(`<del> ${yearBefore.substr(2)}</del> ${yearSource.substr(2)}`);
          str = str.split(`/${yearBefore.substr(2)}`).join(`<del>/${yearBefore.substr(2)}</del> /${yearSource.substr(2)}`);
         
      } else {
          str = str.split(yearTarget).join(yearAfter);
          str = str.split(` ${yearTarget.substr(2)}`).join(` ${yearAfter.substr(2)}`);
          str = str.split(`/${yearTarget.substr(2)}`).join(`/${yearAfter.substr(2)}`);
        
          str = str.split(yearSource).join(yearTarget);
          str = str.split(` ${yearSource.substr(2)}`).join(` ${yearTarget.substr(2)}`);
          str = str.split(`/${yearSource.substr(2)}`).join(`/${yearTarget.substr(2)}`);

          str = str.split(yearBefore).join(yearSource);
          str = str.split(` ${yearBefore.substr(2)}`).join(` ${yearSource.substr(2)}`);
          str = str.split(`/${yearBefore.substr(2)}`).join(`/${yearSource.substr(2)}`);          
      }

      return str;

    }
  },

  watch: {
    dialog: function (val) {
      if (!val) return;
      this.selected.clients = [...new Set(this.query.clients)];
      this.selected.projects = [...this.query.projects];
      this.selected.budgets = [...this.query.budgets];     
    }
  },
};
</script>

<style lang="scss">
del {
  color: red;
}

.current {
  background-color: rgba(0, 0, 0, 0.2);
}

.p-import{
  display: block;
  margin:0;
  padding:0;
  padding-left:8px;
  padding-bottom: 4px;
 white-space: nowrap;
  & > *{
    display: inline-block;
   
  }

  
    
  

  span{
    vertical-align:top;
    padding:0;
    padding-top:8px;
    margin:0;
  }

  .clickable{
    cursor: pointer;
    width:90%;
  }

}

</style>