<template>
  <v-menu
    :value="showing"
    offset-y
    rounded="lg"
    close-on-click
    close-on-content-click
    transition="scale-transition"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="{ ...menu }"
        @click="showing = true"
        >mdi-dots-horizontal</v-icon
      >
    </template>
    <v-list dense>
      <!-- Bearbeiten -->

      <ProjectForm :projectdata="projectdata" @accept="addProject">
        <v-list-item @click="menuClick">
          <v-icon size="20" class="mr-4">mdi-plus</v-icon>
          <v-list-item-title
            >Neue Kampagne für {{ client.groupName }}</v-list-item-title
          >
        </v-list-item>
      </ProjectForm>

      <!-- Duplizieren -->

      <!-- <v-list-item @click="onClick({action: 'duplicate'})">
        <v-icon size="20" class="mr-4">mdi-content-duplicate</v-icon>
         <v-list-item-title>Zeile duplizieren</v-list-item-title>
      </v-list-item> -->

      <!-- Löschen, bzw. wiederherstellen -->

      <!-- <v-list-item @click="onClick({action: 'delete'})">
        <v-icon size="20" class="mr-4">{{ project.deleted_at ? "mdi-delete-restore" : "mdi-delete-outline" }}</v-icon>
         <v-list-item-title>{{ project.deleted_at ? "Zeile behalten" : "Zeile löschen" }}</v-list-item-title>
      </v-list-item> -->

      <v-list-item @click="onClick({ action: 'delete' })">
        <v-icon size="20" class="mr-4">mdi-delete-outline</v-icon>
        <v-list-item-title>{{ deleteOrRestore }}</v-list-item-title>
      </v-list-item>

      <Changelog
        by="client_id"
        :value="client.id"
        within="division_id"
        :withinValue="projectdata.division_id"
        :status="timeline.status"
        :fcid="projectdata.forecast_id"
        :field="timeline.field"
        @changeparams="timelineParamsChange"
      >
        <v-list-item @click="menuClick">
          <v-icon size="20" class="mr-4">mdi-timeline-text-outline</v-icon>
          <v-list-item-title>Timeline</v-list-item-title>
        </v-list-item>
      </Changelog>

      <!-- History -->

      <!-- <History :project="project" v-if="Array.from(project.id)[0] !== '_'">
      <v-list-item @click="menuClick">
                <v-icon size="20" class="mr-4">mdi-timeline-text-outline</v-icon>
              <v-list-item-title>Timeline</v-list-item-title>
            </v-list-item>
      </History>
       <v-list-item v-else @click="notAvailable" style="opacity:0.5">
                <v-icon size="20" class="mr-4">mdi-timeline-text-outline</v-icon>
              <v-list-item-title>Timeline</v-list-item-title>
            </v-list-item> -->

      <!-- Transferieren -->

      <Transfer
        :client="client"
        @accept="transferDone"
        v-if="client.projects.filter((p) => p.parent_id !== 0).length"
      >
        <v-list-item @click="menuClick">
          <v-icon size="20" class="mr-4">mdi-transfer-right</v-icon>
          <v-list-item-title>Transferieren</v-list-item-title>
        </v-list-item>
      </Transfer>
      <v-list-item v-else style="opacity: 0.5">
        <v-icon size="20" class="mr-4">mdi-transfer-right</v-icon>
        <v-list-item-title>Transferieren</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ProjectForm from "@/components/forms/Project";
import History from "@/components/forms/History";
import Transfer from "@/components/forms/Transfer2";
import Changelog from "@/components/Changelog";

export default {
  name: "ClientContextMenu",
  props: ["client", "projectdata", "timeline"],
  data: () => ({
    showing: false,
  }),
  computed: {
    deleteOrRestore() {
      return this.client.projects.filter((p) => p.deleted_at).length <
        this.client.projects.length
        ? "Kunden löschen"
        : "Kunden behalten";
    },
  },
  methods: {
    addProject(projectdata) {
      this.$emit("click", { action: "insert", project: projectdata });
    },
    transferDone(data) {
      this.$emit("click", { action: "transfer", data: data });
    },
    timelineParamsChange(obj){
      this.$emit("click", { action: "timeline", data: obj });
    },
    onClick(item) {
      let data = {
        action: item.action,
        group: this.client,
      };
      this.$emit("click", data);
    },
    menuClick() {
      this.showing = false;
    },
    // myDelete() {
    //   this.project.deleted_at = true;
    // },
    // notAvailable(){
    //   alert("Diese Funktion steht für neu angelegte und noch nicht gespeicherte Elemente nicht zur Verfügung.");
    // }
  },
  components: { ProjectForm, Transfer, History, Changelog },
};
</script>

<style>
</style>