<template>
  <v-tooltip right color="#222" style="opacity: 1" :disabled="disableTooltip" open-delay="500">
    <template v-slot:activator="{ on, attrs }">
      <i v-bind="attrs" v-on="on" v-html="pieChart"></i>
    </template>
    <table class="tooltip mt-4">
      <thead>
        <tr>
          <th colspan="4"><h3>Verteilung Budgetstatus {{ label }}</h3></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-html="pieChart" rowspan="4" class="pt-2 px-4"></td>
        </tr>
        <tr v-for="(row, index) in getTableData" :key="index" :class="{ zero: row.total === '0.00'}">
          <td><v-icon size="18" :color="row.color">mdi-circle</v-icon><span>{{ row.label }}</span></td>
          <td align="right">{{ toLocale(row.percent, 2) }} %</td>
          <td align="right">{{ toLocale(row.total) }} T€</td>
        </tr>
        <tr><td colspan="4" style="padding:.75rem 0 .5rem 0"><hr></td></tr>
        <tr>
          <td colspan="3">Gesamt ./. Fremdkosten:</td>
          <td align="right">{{ toLocale(total) }} T€</td>
        </tr>
      </tbody>
    </table>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
import {fromLocale, toLocale } from "@/utils/numbers";
export default {
  name: "PieChart",
  props: ["data", "localValues", "label","disableTooltip"],
  data() {
    return {
      total: 0,
      segments: {},
    };
  },
  computed: {
    ...mapGetters(["divisions", "clients"]),
    pieChart() {
      let total = 0;
      let segments = {};

      this.$store.state.budget.status.forEach((status) => {
        segments["status_" + status.value] = 0;
      });

      this.data.forEach((p) => {
        //if (p.deleted_at) return;
        //let val = fromLocale(p.budget.total_wo_fk);
        let val = this.localValues ? fromLocale(p.budget.total_wo_fk) : p.budget.total_wo_fk;
        segments["status_" + p.budget.status] += val;
        total+= val;
      });

      if (total === 0) return;

      this.$store.state.budget.status.forEach((status) => {
        segments["status_" + status.value] =
          (100 / total) * segments["status_" + status.value];
      });

      let str = '<div class="pie" style="background-image: conic-gradient(';
      str +=
        this.$store.state.budget.status.find((s) => s.value == 0).hex +
        " " +
        segments.status_0 * 3.6 +
        "deg,";
      str +=
        this.$store.state.budget.status.find((s) => s.value == 1).hex +
        " 0 " +
        (segments.status_0 + segments.status_1) * 3.6 +
        "deg,";
      str +=
        this.$store.state.budget.status.find((s) => s.value == 2).hex + " 0";
      str += '"></div>';

      return str;
    },
    getTableData() {
      let total = 0;
      let tableData = [];
      let segments = {};

      this.$store.state.budget.status.forEach((status) => {
        segments["status_" + status.value] = 0;
      });

      this.data.forEach((p) => {
        //if (p.deleted_at) return;
        let val = this.localValues ? (fromLocale(p.budget.total_wo_fk) || 0) : (p.budget.total_wo_fk || 0);
        segments["status_" + p.budget.status] += val;
        total += val;
      });

      this.total = total;

      this.$store.state.budget.status.forEach((status) => {
        tableData.push({
          color: status.hex,
          label: status.label,
          percent: (100 / total) * segments["status_" + status.value],
          total: segments["status_" + status.value],
        });
      });

      return tableData;
    }
  },
  methods: {
    toLocale,
    getSummary(){
      this.segments = {};
      this.$store.state.budget.status.forEach((status) => {
          this.segments["status_" + status.value] = 0;
      });

      this.total = 0;

      this.data.forEach((p) => {
        //if (p.deleted_at) return;
        let val = this.localValues ? (fromLocale(p.budget.total_wo_fk) || 0) : (p.budget.total_wo_fk || 0);
        this.segments["status_" + p.budget.status] += val;
        this.total += val;
      });
    }
  },
  created(){
   
  }
};
</script>

<style lang="scss">
.pie {
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 0.15em solid #fff;
  box-shadow: 0 0.075em 0.2em 0.05em rgba(0, 0, 0, 0.25);
  background-origin: border-box;
}

table.tooltip {

  tr.zero{
    opacity:.5;
  }

  td {
    padding: 0 1rem;
  }

  th {
    padding: 0 0 1rem 0;
  }

  margin-bottom: 1rem;
}
</style>